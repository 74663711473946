import React ,{ useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Snackbar from '@mui/material/Snackbar';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux'
import { fabric } from "fabric";
import {SketchField, Tools} from './sketch';
import ButtonGroup from '@mui/material/ButtonGroup';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import RectangleOutlinedIcon from '@mui/icons-material/RectangleOutlined';
import ChangeHistoryOutlinedIcon from '@mui/icons-material/ChangeHistoryOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import PhotoSizeSelectSmallIcon from '@mui/icons-material/PhotoSizeSelectSmall';
import DeselectIcon from '@mui/icons-material/Deselect';
import AddIcon from "@mui/icons-material/Add";
import qs, { parse } from 'qs'
import axios from 'axios';
import { API_URL, ESHOW_URL } from '../../config.js'
import { download, getTokenCookie } from '../../utils/Common.js'

import { base_json, base_entity, base_text_entity } from '../../dummy.js'
import '../Main.css';
import { setLoading } from '../../actions/main'
import wordwrap from 'wordwrapjs'
import { setObjectBG, getBGColor, px2unit, unit2Px, getStrokeWidth } from '../../utils/CanvasTool'
import BigNumber from "bignumber.js";
import moment from 'moment';

import { toXML } from 'jstoxml'
var g_isDragging = false
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
const xml_config = {
  indent: ' '
}

var step2Canvas
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function ExportFile(props) {
  const handleClose = () => {
    props.onClose()
  };
  const mainCanvasRef = useRef(null);
  const [value, setValue] = React.useState('full');
  const [valueFormat, setValueFormat] = React.useState('dwg');
  const [checked, setChecked] = React.useState(true);
  const [step, setStep] = React.useState(1)
  const [tool, setTool] = React.useState(Tools.Pan)
  const [_sketch,setSketch] = React.useState(null)
  const [viewMode, setViewMode] = React.useState("full")
  const [showBoothNumber,setShowBoothNumber] = React.useState(true)
  const [showBoothColorSold, setShowBoothColorSold] = React.useState(false)
  const [showBoothColorHold, setShowBoothColorHold] = React.useState(false)
  const [showBoothColorAvailable, setShowBoothColorAvailable] = React.useState(false)
  const [showBoothColorPending, setShowBoothColorPending] = React.useState(false)
  const [showBoothSize,setShowBoothSize] = React.useState(false)
  const [showCompanyName, setShowCompanyName] = React.useState(true)
  const [showBG, setShowBG] = React.useState(true)  
  const [snackMsg, setSnackMsg] = useState('');
  const [openSnack, setOpenSnack] = useState(false);

  
  var mainCanvas = props.main.mainCanvas
const d = new Date();
const datestring = moment().format("MM_DD_YYYY");
const Layer1 = 'ESHOW_OUTLINE_FINAL_'+ datestring
const Layer2 = 'ESHOW_NUMBERS_FINAL_'+ datestring
const Layer3 = 'ESHOW_OUTLINE_DELETED_'+ datestring
const Layer4 = 'ESHOW_NUMBERS_DELETED_'+ datestring
const Layer5 = 'ESHOW_COMPANY_FINAL_'+ datestring


  const handleChangeViewMode = (event) => {
    setViewMode(event.target.value);
  };

  const handleChangeFormat = (event) => {
    setValueFormat(event.target.value);
  };

  const handleCloseSnack = ()=>{
    setOpenSnack(false);
  }

  const handleChange1 = (event) => {
    setChecked(event.target.checked);
  };

  const handleChangeBoothNumber = (event) => {
    setShowBoothNumber(event.target.checked);
  };
  const handleChangeBoothColorSold = (event) => {
    setShowBoothColorSold(event.target.checked);
  };
  const handleChangeBoothColorHold = (event) => {
    setShowBoothColorHold(event.target.checked);
  };
  const handleChangeBoothColorAvailable = (event) => {
    setShowBoothColorAvailable(event.target.checked);
  };
  const handleChangeBoothColorPending = (event) => {
    setShowBoothColorPending(event.target.checked);
  };
  const handleChangeBoothSize = (event) => {
    setShowBoothSize(event.target.checked);
  };
  const handleChangeCompanyName = (event) => {
    setShowCompanyName(event.target.checked);
  };
  const handleChangeBG= (event) => {
    setShowBG(event.target.checked);
  };
  const exportImage = ()=>{
    handleClose();
    // var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
    // var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
    // var mapWidth = Math.abs(props.main.mainCanvas.endX - props.main.mainCanvas.startX);
    // var mapHeight = Math.abs(props.main.mainCanvas.endY - props.main.mainCanvas.startY);
    // var orgwidth = mapWidth/convertRateX;
    // var orgHeight = mapHeight/convertRateX;

    var el = document.createElement("a");
    var option
    if(viewMode == "full"){
      option = {
        format: 'png',
        multiplier: 5,
        left:_sketch._fc.viewportTransform[4],
        top:_sketch._fc.viewportTransform[5],
        width:Math.abs(props.main.mainCanvas.endX-props.main.mainCanvas.startX)*_sketch._fc.viewportTransform[0]*0.5+10,
        height:Math.abs(props.main.mainCanvas.endY-props.main.mainCanvas.startY)*_sketch._fc.viewportTransform[0]*0.5+10
      }
    }
    else{
      option = {
        format: 'png',
        multiplier: 5,
        left:_sketch._fc.viewportTransform[4],
        top:_sketch._fc.viewportTransform[5],
        width:window.innerWidth*_sketch._fc.viewportTransform[0]*0.5+10,
        height:window.innerHeight*_sketch._fc.viewportTransform[0]*0.5+10
      }
    }
    
    el.setAttribute("href", _sketch.toDataURL(option))
    el.setAttribute("download", "booth.png");
    document.body.appendChild(el)
    el.click();
    el.remove();
    restoreCavas();
  }


const buildPathFromSVGRect = (startX, startY, booth)=>{ 
  var convertRateX = 1;
  if(props.main.mainCanvas.convertRateX){
      convertRateX = props.main.mainCanvas.convertRateX
  }
  convertRateX = convertRateX
  var left = startX+booth['left']-50
  var top = startY+booth['top']-50;
  var width = booth['width'];
  var height = booth['height'];
  var color = (booth['sold'] == "1")?0xff0000:0x817d7a;
  var vertices = []
  var polygons = booth.getObjects('polygon')
  // if(polygons[0]){
  //   var points = polygons[0].points
  //   for(var i = 0; i < points.length; i++){
  //     var point = points[i]
  //     var vertice = 
  //     {
  //       "Index": 1,
  //       "Segment_Type": "Line",
  //       "Location": {
  //         "x": (left+point.x)*convertRateX-(props.main.mainCanvas.get('convertOffsetX')?props.main.mainCanvas.get('convertOffsetX'):0),
  //         // "y": (top+point.y)*convertRateX-(props.main.mainCanvas.get('convertOffsetY')?props.main.mainCanvas.get('convertOffsetY'):0),
  //         "y": (top+point.y)*convertRateX ,
  //         "z": "0"
  //       }
  //     }
  //     vertices.push(vertice);
  //   }
  // }
  // var vertice = 
  // {
  //   "Index": 1,
  //   "Segment_Type": "Line",
  //   "Location": {
  //     "x": (left)*convertRateX-(props.main.mainCanvas.get('convertOffsetX')?props.main.mainCanvas.get('convertOffsetX'):0),
  //     "y": (top)*convertRateX-(props.main.mainCanvas.get('convertOffsetY')?props.main.mainCanvas.get('convertOffsetY'):0),
  //     "z": "0"
  //   }
  // }
  // vertices.push(vertice);
  // vertice = 
  // {
  //   "Index": 2,
  //   "Segment_Type": "Line",
  //   "Location": {
  //     "x": (left+width)*convertRateX-(props.main.mainCanvas.get('convertOffsetX')?props.main.mainCanvas.get('convertOffsetX'):0),
  //     "y": (top)*convertRateX-(props.main.mainCanvas.get('convertOffsetY')?props.main.mainCanvas.get('convertOffsetY'):0),
  //     "z": "0"
  //   }
  // }
  // vertices.push(vertice);
  // vertice = 
  // {
  //   "Index": 3,
  //   "Segment_Type": "Line",
  //   "Location": {
  //     "x": (left+width)*convertRateX-(props.main.mainCanvas.get('convertOffsetX')?props.main.mainCanvas.get('convertOffsetX'):0),
  //     "y": (top+height)*convertRateX-(props.main.mainCanvas.get('convertOffsetY')?props.main.mainCanvas.get('convertOffsetY'):0),
  //     "z": "0"
  //   }
  // }
  // vertices.push(vertice);
  // vertice = 
  // {
  //   "Index": 4,
  //   "Segment_Type": "Line",
  //   "Location": {
  //     "x": (left)*convertRateX-(props.main.mainCanvas.get('convertOffsetX')?props.main.mainCanvas.get('convertOffsetX'):0),
  //     "y": (top+height)*convertRateX-(props.main.mainCanvas.get('convertOffsetY')?props.main.mainCanvas.get('convertOffsetY'):0),
  //     "z": "0"
  //   }
  // }
  // vertices.push(vertice);
  var convertOffsetX = (props.main.mainCanvas.get('convertOffsetX')?props.main.mainCanvas.get('convertOffsetX'):0)
  var convertOffsetY = (props.main.mainCanvas.get('convertOffsetY')?props.main.mainCanvas.get('convertOffsetY'):0)
  var vertice = 
  {
    "Index": 1,
    "Segment_Type": "Line",
    "Location": {
      "x": new BigNumber(left).multipliedBy(convertRateX).plus(convertOffsetX).toNumber(),
      "y": new BigNumber(convertOffsetY).minus(new BigNumber(top).multipliedBy(convertRateX)).toNumber(),
      "z": "0"
    }
  }
  vertices.push(vertice);
  vertice = 
  {
    "Index": 2,
    "Segment_Type": "Line",
    "Location": {
      "x": new BigNumber(left+width).multipliedBy(convertRateX).plus(convertOffsetX).toNumber(),
      "y": new BigNumber(convertOffsetY).minus(new BigNumber(top).multipliedBy(convertRateX)).toNumber(),
      "z": "0"
    }
  }
  vertices.push(vertice);
  vertice = 
  {
    "Index": 3,
    "Segment_Type": "Line",
    "Location": {
      "x": new BigNumber(left+width).multipliedBy(convertRateX).plus(convertOffsetX).toNumber(),
      "y": new BigNumber(convertOffsetY).minus(new BigNumber(top+height).multipliedBy(convertRateX)).toNumber(),
      "z": "0"
    }
  }
  vertices.push(vertice);
  vertice = 
  {
    "Index": 4,
    "Segment_Type": "Line",
    "Location": {
      "x": new BigNumber(left).multipliedBy(convertRateX).plus(convertOffsetX).toNumber(),
      "y": new BigNumber(convertOffsetY).minus(new BigNumber(top+height).multipliedBy(convertRateX)).toNumber(),
      "z": "0"
    }
  }
  
  if(booth.booth_number == "359"){
    console.log("359", vertice)
  }
  if(booth.booth_number == "357"){
    console.log("357", vertice)
  }
  vertices.push(vertice);

  return vertices;
}

const buildPathFromSVGPath = (startX, startY, booth)=>{  
  var left = startX+booth['left'];
  var top = startY+booth['top'];
  var width = booth['width'];
  var height = booth['height'];
  var color = (booth['sold'] == "1")?0xff0000:0x817d7a;
  var vertices = []
  var vertices_arry = [];
  var objects = booth.getObjects();
  for(var i = 0; i < objects.length; i++){
    var object = objects[i]
    if(object['type'] == 'polygon'){
      var points = object['points'];
      for(var pathIndex = 0; pathIndex < points.length; pathIndex++){
        var point = points[pathIndex];
        if(vertices.length > 0){
          vertices_arry.push([...vertices]);
          vertices = [];
        }
        var vertice = 
        {
          "Index": pathIndex+1,
          "Segment_Type": "Line",
          "Location": {
            "x": (left + point['x']-50)*props.main.mainCanvas.get('convertRateX')-(props.main.mainCanvas.get('convertOffsetX')?props.main.mainCanvas.get('convertOffsetX'):0),
            "y": (top + point['y']-50)*props.main.mainCanvas.get('convertRateX')-(props.main.mainCanvas.get('convertOffsetY')?props.main.mainCanvas.get('convertOffsetY'):0),
            "z": "0"
          }
        }
        vertices.push({...vertice});
      }
      vertices_arry.push([...vertices]);
    }
  }
  return vertices_arry;
}

const buildTextEntity = (startX, startY, booth)=>{
  var left = startX+booth['left']-50;
  var top = startY+booth['top']-50;
  var width = booth['width'];
  var height = booth['height'];
  var color = (booth['sold'] == "1")?0xff0000:0x817d7a;
  var entities = [];
  var objects = booth.getObjects()
  
  var convertRateX = 1;
  if(props.main.mainCanvas.convertRateX){
      convertRateX = props.main.mainCanvas.convertRateX
  }
  convertRateX = convertRateX
  var marginX = 5/Math.max(convertRateX, 1) + 2/Math.max(convertRateX, 1)
  var convertOffsetX = (props.main.mainCanvas.get('convertOffsetX')?props.main.mainCanvas.get('convertOffsetX'):0)
  var convertOffsetY = (props.main.mainCanvas.get('convertOffsetY')?props.main.mainCanvas.get('convertOffsetY'):0)
  for(var i = 0; i < objects.length; i++){  
    var entity = {...base_text_entity};
    var text_String = "";
    var text_Position;
    //console.log("booth", booth)
    var object = objects[i]
    if(object['type'] == 'text' && object['class1'] != 'hidden'){
      text_String = booth['booth_number'];
      // if(object.get('class') == "companyname"){
      //   text_Position = {
      //     x:(left-50-(text_String.length*8/2))*props.main.mainCanvas.get('convertRateX')-(props.main.mainCanvas.get('convertOffsetX')?props.main.mainCanvas.get('convertOffsetX'):0),
      //     y:(top-50-30)*props.main.mainCanvas.get('convertRateX')-(props.main.mainCanvas.get('convertOffsetY')?props.main.mainCanvas.get('convertOffsetY'):0),
      //     z:0
      //   };      
      // }
      // else if(object.get('class1') == "textY"){
        
      // }
      // else if(object.get('class1') == "textY"){
        
      // }
      // else{
        text_Position = {
          x:(left + marginX)*convertRateX+convertOffsetX,
          y:convertOffsetY - (top + booth.height -  marginX)*convertRateX,
          z:0
        };        
      // }      
      entity.Text_String = text_String
      entity.Text_Position = text_Position
      entity.Text_Height = 15      
      entity.Layer = (booth['layer']=='deleted'?Layer4:Layer2)
      entities.push(entity)
      if(booth.company && booth.company!= ""){ 
        var Text_Height = 15       
        var entity = {...base_text_entity};
        var text_String = "";
        var text_Position;
        text_String = booth['company'];
        text_Position = {
          x:(left + marginX)*convertRateX+convertOffsetX,
          y:convertOffsetY - (top + booth.height -  marginX)*convertRateX + Text_Height*1.2,
          z:0
        };      
        entity.Text_String = text_String
        entity.Text_Position = text_Position
        entity.Text_Height = Text_Height      
        entity.Layer = (booth['layer']=='deleted'?Layer4:Layer5)
        entities.push(entity)
      }
    }
  }


  // entity = 
  // {
  //   "Type": "Text",
  //   "Handle": "143",
  //   "Layer": "Layer1",
  //   "Color_Index": "256",
  //   "Color": "ByLayer",
  //   "Linetype": "ByLayer",
  //   "Normal": {
  //     "x": "0",
  //     "y": "0",
  //     "z": "1"
  //   },
  //   "Text_String": "?his is a simple text his is a simple text his is a simple text his is a simple text his is a simple text his is a simple text",
  //   "Text_Position": {
  //     "x": "500",
  //     "y": "100",
  //     "z": "0"
  //   },
  //   Font_Size: 100,
  //   Text_Size: 100,
  //   String_Size:100,
  //   font_size:100,
  //   text_size:100,
  //   size:100,
  //   Size:100
  // }

  return entities
}  

const checkIfInsideScreen = (currentObject)=>{
  if (!currentObject || !currentObject.visible) {
    return false;
  }
  var vptCoords = props.main.mainCanvas.vptCoords
  var
  selectionX1Y1 = new fabric.Point(vptCoords.tl.x, vptCoords.tl.y),
  selectionX2Y2 = new fabric.Point(vptCoords.br.x, vptCoords.br.y);

  if (
      currentObject.isContainedWithinRect(selectionX1Y1, selectionX2Y2) 
  ) {
      return true;
  }
  return false
}
const buildEntities = (objects)=>{
  //console.log('content', objects)
  var entities = [];
  for(var i = 0; i< objects.length; i++){
    var booth = objects[i]   
    if(viewMode == "current"){
      if(!booth.isOnScreen()) continue;
    }
    var convertRateX= 1
    var convertRateX= 1
    if(mainCanvas.convertRateX) {
        var convertRateX= Math.abs(mainCanvas.convertRateX)
        var convertRateX= Math.abs(mainCanvas.convertRateX)
    }
    var mapOffsetX = unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
    var mapOffsetY = unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)
    var startX = -mapOffsetX;
    var startY = -mapOffsetY;
    var vertices;
    if(booth['class'] == 'booth'){
        var entity = {...base_entity};
        vertices = buildPathFromSVGRect(startX, startY, booth)
        var polygons = booth.getObjects('polygon')
        var Constant_Width = 1;
        if(polygons[0]){
          var strokeWidth = polygons[0].strokeWidth
          Constant_Width = (strokeWidth)*convertRateX
        }
        entity.Constant_Width = 0.001
        entity.Vertices = vertices
        entity.Layer = (booth['layer']=='deleted'?Layer3:Layer1)
        entity.Closed = 'True'
        entities.push(entity) 

        var sub_entities = buildTextEntity(startX, startY, booth)
        if(sub_entities){
          for(var j = 0; j< sub_entities.length; j++){
            entities.push(sub_entities[j]) 
          }
        }
    }
  }
  return entities;
}
const exportDwg = ()=>{
  
  props.setLoading(true)
  var dwg_path;
  var activeFile = props.main.active_booth_file

  if(activeFile){
    dwg_path = activeFile['dwgPath']?activeFile['dwgPath']:`content/drawings/dwg/blank.dwg`;
    // props.main.mainCanvas.set({convertRateX:activeFile['convertRateX'], convertRateX:activeFile['convertRateX'], convertOffsetX:activeFile['convertOffsetX'], convertOffsetY:activeFile['convertOffsetY']})
  }
  else{
    var view_file = props.main.view_file;
    if(view_file)
      dwg_path = view_file?view_file.dwgPath:`content/drawings/dwg/blank.dwg`;
  }
  
  var json_content = {...base_json}
  var layers = [];
  var path_layer = 
    {
      "Name": Layer1,
      "On": "True",
      "Frozen": "False",
      "Color": "Foreground",
      "Linetype": "Continuous",
      "Lineweight": "kLnWtByLwDefault"
    }
  layers.push(path_layer)
  var text_layer = 
    {
      "Name": Layer2,
      "On": "True",
      "Frozen": "False",
      "Color": "Foreground",
      "Linetype": "Continuous",
      "Lineweight": "kLnWtByLwDefault"
    }
  layers.push(text_layer)
  var deleted_path_layer = 
    {
      "Name": Layer3,
      "On": "True",
      "Frozen": "False",
      "Color": "Foreground",
      "Linetype": "Continuous",
      "Lineweight": "kLnWtByLwDefault"
    }
  layers.push(deleted_path_layer)
  var deleted_text_layer = 
    {
      "Name": Layer4,
      "On": "True",
      "Frozen": "False",
      "Color": "Foreground",
      "Linetype": "Continuous",
      "Lineweight": "kLnWtByLwDefault"
    }
  layers.push(deleted_text_layer)
  var company_layer = 
  {
    "Name": Layer5,
    "On": "True",
    "Frozen": "False",
    "Color": "Foreground",
    "Linetype": "Continuous",
    "Lineweight": "kLnWtByLwDefault"
  }
  layers.push(company_layer)
  json_content.Drawing.Layers = layers;

  // var booth_content = props.main.mainCanvas.toDatalessObject()
  var booth_content = props.main.mainCanvas.getObjects()
  if(booth_content){
      var entities = buildEntities(booth_content)
      //console.log("entities", entities)
      var block = 
      {
        "Block_Name": "*Model_Space",
        "Filename": "",
        "Loaded": "True",
        "Overlay": "False",
        "External_Reference": "False",
        "Path_Name": "",
        "Origin": {
          "x": "0",
          "y": "0",
          "z": "0"
        },
        "Entities": entities
      }
      json_content.Drawing.Blocks = [];
      json_content.Drawing.Blocks.push(block);
  }

  if(dwg_path){
    var url = `/exportdwg`
    
    let outputName = "output.dwg"
    if(activeFile['dwgName']){
      let dwgName = activeFile['dwgName']
      let extension = dwgName.substring(dwgName.lastIndexOf('.') + 1, dwgName.length);
      let name = dwgName.substring(0, dwgName.lastIndexOf('.')-1);
      outputName = `${name}_${moment().format("MM_DD_YYYY")}.${extension}`
    }

    var formdata = 
    {
        // content:(toXML(content,xml_config))
        content:JSON.stringify(json_content),
        dwg_path:dwg_path,
        output_name:outputName
    }
    var data = qs.stringify({
        request: (formdata)
    });
    const options = {
        headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    axios.post(url, data, options).then(res=>{
        const result = res.data;
        if(result.status == "success"){
           //console.log("download dwg success")
           

          download(`${API_URL()}/converters/dwg/${result.filename}`, outputName);
          restoreCavas()
          props.setLoading(false)
        }
        else{
          props.setLoading(false)
        }
    }).catch(error => {
        //console.log("error", error);
    })
  }
  else{    
    setOpenSnack(true);
    setSnackMsg("Upload file first");
    props.setLoading(false)
  }
}
  const exportPdf = ()=>{
    var mainSvg;
    props.setLoading(true)
    if(viewMode == "full"){
      mainSvg = step2Canvas.toSVG({
        width:'100%',
        height: '100%',
        viewBox: {          
            x:0,
            y:0,
            width:Math.abs(props.main.mainCanvas.endX-props.main.mainCanvas.startX+50),
            height:Math.abs(props.main.mainCanvas.endY-props.main.mainCanvas.startY+50)
        }
        

      });
    }
    else{
      mainSvg = step2Canvas.toSVG();
    }
    var url = `/generatepdf`
    var formdata = 
    {
        content:mainSvg,
        width:Math.abs(props.main.mainCanvas.endX-props.main.mainCanvas.startX+50),
        height:Math.abs(props.main.mainCanvas.endY-props.main.mainCanvas.startY+50),
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
        headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    axios.post(url, data, options).then(res=>{
        const result = res.data;
        if(result.status == "success"){
          download(`${API_URL()}file.pdf`, 'booth.pdf');
          props.setLoading(false)
        }
        else{
          props.setLoading(false)
        }
    }).catch(error => {
        //console.log("error", error);
        props.setLoading(false)
    })
    restoreCavas();
}

const exportDwgAPI = (format)=>{
  {
    var url = `/generate`
    
  var activeFile = props.main.active_booth_file
    let outputName = "output.dwg"
    if(activeFile['dwgName']){
      let dwgName = activeFile['dwgName']
      let extension = dwgName.substring(dwgName.lastIndexOf('.') + 1, dwgName.length);
      let name = dwgName.substring(0, dwgName.lastIndexOf('.')-1);
      outputName = `${name}_${moment().format("MM_DD_YYYY")}.${extension}`
    }

    props.setLoading(true)
    var formdata = 
    {
      'FORMAT': format,
      'BOOTH_LINE': '1',
      'BOOTH_NUMBERS': '1',
      'BOOTH_SIZE': format=='DWG'?'1':(showBoothSize?'1':'0'),
      'COMPANY_NAME': format=='DWG'?'1':(showCompanyName?'1':'0'),
      'BG_IMAGE': showBG?'1':'0',
      'BOOTH_COLOR_SOLD': showBoothColorSold?'1':'0',
      'BOOTH_COLOR_AVAILABLE': showBoothColorAvailable?'1':'0',
      'BOOTH_COLOR_HOLD': showBoothColorHold?'1':'0',
      'BOOTH_COLOR_PENDING': showBoothColorPending?'1':'0',
      'ESHOW_URL': ESHOW_URL(),
      'MAP_KEY': activeFile.KEY_ID,
      'TOKEN': getTokenCookie()
    }
    var data = qs.stringify(formdata);
    const options = {
        headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    axios.post(url, data, options).then(res=>{
        const result = res.data;
        if(result.status == "success"){

          download(`${result.url}`, result.fileName);
          restoreCavas()
          props.setLoading(false)
          handleClose();
        }
        else{
          props.setLoading(false)
        }
    }).catch(error => {
        setOpenSnack(true);
        setSnackMsg("Failed to export");
        props.setLoading(false)
    })
  }
}

const exportPdfAPI = ()=>{
  
}

const onMouseDown = (opt, that)=>{           
  var evt = opt.e;
  g_isDragging = true; 
  that.selection = false;
  that.lastPosX = evt.clientX;
  that.lastPosY = evt.clientY;
}

  useEffect(() => {
    if(props.main.authInfo?.role == 'admin'){
      setValueFormat("dwg")
    }
    else{
      setValueFormat("pdf")      
      setShowBoothSize(false) 
      setShowBoothColorSold(true) 
      setShowBoothColorHold(true) 
      setShowBoothColorAvailable(true) 
      setShowBoothColorPending(true)
    }
    var bgImgCanvas = props.main.bgImgCanvas
    var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
    var bg_url = null;
    var bg_width = 0;
    var bg_height = 0;
    var bg_offset_x = 0;
    var bg_offset_y = 0;
    var bg_scale = 1;
    var bg_width_feet = 0;
    var bg_height_feet = 0;
    var bg_offset_x_feet = 0;
    var bg_offset_y_feet = 0;
    var backgroundImage = bgImgCanvas.backgroundImage
    if(backgroundImage && backgroundImage.src){
      bg_url = backgroundImage.src
    }
    else if(backgroundImage && backgroundImage._element){
      bg_url = backgroundImage._element.currentSrc
    }
    var mapOffsetX = unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
    var mapOffsetY = unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)
    if(backgroundImage){      
      bg_width = backgroundImage.width?backgroundImage.width:3000
      bg_height = backgroundImage.height?backgroundImage.height:3000
      bg_scale = backgroundImage.scaleX
      bg_offset_x = backgroundImage.left?backgroundImage.left-50-mapOffsetX:0
      bg_offset_y = backgroundImage.top?backgroundImage.top-50-mapOffsetY:0
    }
    // setLoadingFlag(true)
    setTimeout(function(){      
      fabric.Object.NUM_FRACTION_DIGITS  = 15;
      step2Canvas = new fabric.Canvas("step-2-canvas");
      step2Canvas.set({preserveObjectStacking: true, altActionKey: 'ctrlKey', altSelectionKey: 'ctrlKey'})
      step2Canvas.defaultCursor = 'pointer';
      step2Canvas.off('mouse:down');
      step2Canvas.on('mouse:down', function(opt) {
          onMouseDown(opt, this)            
      });
      
      step2Canvas.off('mouse:up');
      step2Canvas.on('mouse:up', function(opt) { 
        g_isDragging = false; 
      });
      step2Canvas.off('mouse:move')    
      step2Canvas.on('mouse:move', function(opt) {
          var evt = opt.e;
          if (g_isDragging) {
            var e = opt.e;
            var vpt = this.viewportTransform;
            var deltaX = e.clientX - this.lastPosX;
            var deltaY = e.clientY - this.lastPosY;
            vpt[4] += deltaX;
            vpt[5] += deltaY;
            this.requestRenderAll();

            this.lastPosX = e.clientX;
            this.lastPosY = e.clientY;
          }
      });
      props.setLoading(true)
      var content = mainCanvas.toDatalessObject()
      content.background = null;
      step2Canvas.loadFromJSON(content, ()=>{
        if(mainCanvas.convertRateX){
            convertRateX = mainCanvas.convertRateX
        }
        adjustFont()
        adjustImage()
        step2Canvas.discardActiveObject();
        step2Canvas.forEachObject(function(object) {
            object.evented = false;
            object.selectable = false; 
            object.selection=false;
            object.hasControls=false;
            object.hasBorders=false;
            object.lockMovementX= true;
            object.lockMovementY= true;
            object.lockRotation= true;
            object.lockScalingX= true;
            object.lockScalingY= true;
        });

        var offsetX_PX = bg_offset_x;
        var offsetY_PX = bg_offset_y;
        if(bg_url){
          var imgObj = new Image();
          imgObj.crossOrigin = 'Anonymous';
          imgObj.onload = function(oImg) {
            var tempCanvas = document.createElement('CANVAS');
            var tempCtx = tempCanvas.getContext('2d');
            var height = tempCanvas.height = this.naturalHeight;
            var width = tempCanvas.width = this.naturalWidth;
            tempCtx.drawImage(this, 0, 0);
            var dataURL = tempCanvas.toDataURL();
            step2Canvas.setBackgroundImage(dataURL, previewCallback.bind(step2Canvas), {
              opacity: 0.5,
              angle: 0,
              left: offsetX_PX+50+mapOffsetX,
              top: offsetY_PX+50+mapOffsetY,
              originX: 'left',
              originY: 'top',
              scaleX: bg_scale?bg_scale:1,
              scaleY: bg_scale?bg_scale:1,
              crossOrigin: 'anonymous'
            });
          }
          imgObj.src = bg_url;
        }
        else{
          step2Canvas.setBackgroundImage(null);
        }
        swichFullView();
        props.setLoading(false)
        // setLoadingFlag(false)

      })
    },100)
  }, [])
  useEffect(() => {
    if(!step2Canvas) return
    var bgImgCanvas = props.main.bgImgCanvas
    var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
    var bg_url = null;
    var bg_width = 0;
    var bg_height = 0;
    var bg_offset_x = 0;
    var bg_offset_y = 0;
    var bg_scale = 1;
    var bg_width_feet = 0;
    var bg_height_feet = 0;
    var bg_offset_x_feet = 0;
    var bg_offset_y_feet = 0;
    var backgroundImage = bgImgCanvas.backgroundImage
    if(backgroundImage && backgroundImage.src){
      bg_url = backgroundImage.src
    }
    else if(backgroundImage && backgroundImage._element){
      bg_url = backgroundImage._element.currentSrc
    }
    var mapOffsetX = unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
    var mapOffsetY = unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)
    if(backgroundImage){      
      bg_width = backgroundImage.width?backgroundImage.width:3000
      bg_height = backgroundImage.height?backgroundImage.height:3000
      bg_scale = backgroundImage.scaleX
      bg_offset_x = backgroundImage.left?backgroundImage.left-50-mapOffsetX:0
      bg_offset_y = backgroundImage.top?backgroundImage.top-50-mapOffsetY:0
    }
    // setLoadingFlag(true)
      props.setLoading(true)
        if(mainCanvas.convertRateX){
            convertRateX = mainCanvas.convertRateX
        }
        var offsetX_PX = bg_offset_x;
        var offsetY_PX = bg_offset_y;
        if(bg_url && showBG){
          var imgObj = new Image();
          imgObj.crossOrigin = 'Anonymous';
          imgObj.onload = function(oImg) {
            var tempCanvas = document.createElement('CANVAS');
            var tempCtx = tempCanvas.getContext('2d');
            var height = tempCanvas.height = this.naturalHeight;
            var width = tempCanvas.width = this.naturalWidth;
            tempCtx.drawImage(this, 0, 0);
            var dataURL = tempCanvas.toDataURL();
            step2Canvas.setBackgroundImage(dataURL, previewCallback.bind(step2Canvas), {
              opacity: 0.5,
              angle: 0,
              left: offsetX_PX+50+mapOffsetX,
              top: offsetY_PX+50+mapOffsetY,
              originX: 'left',
              originY: 'top',
              scaleX: bg_scale?bg_scale:1,
              scaleY: bg_scale?bg_scale:1,
              crossOrigin: 'anonymous'
            });
          }
          imgObj.src = bg_url;
        }
        else{
          step2Canvas.setBackgroundImage(null);
        }
      props.setLoading(false)
  }, [showBG])
  

  const adjustFont = ()=>{
    var convertRateX = 1;
    if(mainCanvas.convertRateX){
        convertRateX = mainCanvas.convertRateX
    }
    var fontSize = 50/Math.max(convertRateX, 1)
    var minFontSize = 1
    if(mainCanvas.mapUnit == 'meter'){
      minFontSize = unit2Px(0.5, convertRateX, mainCanvas.mapUnit)
    }
    else{
      minFontSize = unit2Px(2, convertRateX, mainCanvas.mapUnit)
    }
    fontSize = minFontSize;// Math.min(fontSize, minFontSize)
    
    var maxFontSize = 1;    
    if(mainCanvas.mapUnit == 'meter'){
      maxFontSize = unit2Px(0.6, convertRateX, mainCanvas.mapUnit)
    }
    else{
      maxFontSize = unit2Px(2, convertRateX, mainCanvas.mapUnit)
    }
    let maxScale = maxFontSize/fontSize;

    let idealSize = 0;

    if(mainCanvas.mapUnit == 'meter'){
        idealSize = 0.3;
    }
    else{
        idealSize = 1.5;
    }

    var diemensionFontSize = unit2Px(1.5, convertRateX, mainCanvas.mapUnit)
    // if(!showBoothSize){
    //   diemensionFontSize = 0;
    // }
    var objects = step2Canvas.getObjects('group');
    var newScaleCompany = 1;
    var newScaleBooth = 100;
    // var marginX =  0.5*12/Math.max(convertRateX, 1) // 0.5feet
    var marginX = getStrokeWidth(mainCanvas)*2
    var boothNameHeightDefault = 1;
    for(var i = 0; i< objects.length; i++){
      if(objects[i].get('class')=="booth"){
        var group = objects[i];
        var textobjects = objects[i].getObjects('text');
        var f_text_booth = null;
        var f_text_company = null;
        for(var j = 0; j<  textobjects.length; j++){
            // if(textobjects[j].class == "boothName"){
              group.remove(textobjects[j])
            // }
        }
        var companyString = ""
        if(group.company){
          if(group.company)
            companyString = `${group.company}`.trim()
          // companyString = companyString.replaceAll(" ", "\n")
          var limitedWidth = group.width - marginX - diemensionFontSize
          var limitedCharLength = limitedWidth/(fontSize/2)
          
          companyString = wordwrap.wrap(companyString, {width: limitedCharLength})
          console.log("companyString2", companyString)
          f_text_company = new fabric.Text(companyString, { 
            fontSize:fontSize,
            class:'boothName',
            class1:'companyName',
            objectCaching: false,
          });
          group.add(f_text_company)
        }
        var booth_number = ""
        if(group.booth_number){
            booth_number = group.booth_number
            f_text_booth = new fabric.Text(booth_number, { 
              fontSize:fontSize,
              class:'boothName',
              class1:'boothName',
              objectCaching: false
            });
            group.add(f_text_booth)
            boothNameHeightDefault = f_text_booth.height
        }
        var booth_width = 0
        var booth_height = 0
        booth_width = (px2unit(group.width, convertRateX, mainCanvas.mapUnit).toFixed(0).replace(/\.00$/, ""))
        booth_height = (px2unit(group.height, convertRateX, mainCanvas.mapUnit).toFixed(0).replace(/\.00$/, ""))
        var f_text_width = new fabric.Text(booth_width, {          
          fontSize:diemensionFontSize,
          class:'size',
          class1:'width',
          visible: (props.main.authInfo?.role == 'admin' || 1)?showBoothSize:false
        });    
        group.add(f_text_width);
        f_text_width.set({
          left: 0-f_text_width.width/2,
          top: 0-group.height/2,
        })
        var f_text_height = new fabric.Text(booth_height, {
            fontSize:diemensionFontSize,
            class:'size',
            class1:'height',
            visible: (props.main.authInfo?.role == 'admin' || 1)?showBoothSize:false
        });    
        group.add(f_text_height);
        f_text_height.set({
          angle: 90,
          left: 0+group.width/2,
          top: 0-f_text_height.width/2,
        })
      }
    }
    for(var i = 0; i< objects.length; i++){
      if(objects[i].get('class')=="booth"){
        var group = objects[i];
        var textobjects = objects[i].getObjects('text');
        var f_text = null;
        for(var j = 0; j<  textobjects.length; j++){
          if(textobjects[j].class == "boothName"){
              if(textobjects[j].class1 == "companyName"){
                var f_text = textobjects[j]
                var scale = (group.width - marginX - diemensionFontSize)/f_text.width
                // newScaleCompany = Math.min(scale, newScaleCompany);
                group.newScaleCompany = scale
              }
              else if(textobjects[j].class1 == "boothName"){
                var f_text = textobjects[j]
                var scale = (group.width - marginX - diemensionFontSize)/f_text.width
                if(scale > 0)
                  newScaleBooth = Math.min(scale, newScaleBooth);
              }
          }
        }
      }
    }
    newScaleBooth = Math.min(maxScale, newScaleBooth)

    for(var i = 0; i< objects.length; i++){
      if(objects[i].get('class')=="booth"){
        var group = objects[i];
        var textobjects = objects[i].getObjects('text');
        var f_text = null;
        for(var j = 0; j<  textobjects.length; j++){
          if(textobjects[j].class == "boothName"){
            if(textobjects[j].class1 == "boothName"){
              var f_text = textobjects[j]
              var textHeight = f_text.height*newScaleBooth;
              var newLeft = 0 - group.width/2 + marginX
              var newTop = 0 + group.height/2 - marginX*1 - textHeight
              if(f_text){
                f_text.set({      
                    scaleX: newScaleBooth,
                    scaleY: newScaleBooth,
                    textAlign:'left',       
                    left: newLeft, 
                    top:newTop,
                })
              }
            }
            else if(textobjects[j].class1 == "companyName"){
              var availableWidth = (group.width - marginX - diemensionFontSize)
              var availableHeight = (group.height - marginX - diemensionFontSize)
              var f_text = textobjects[j]
              let totalTextWidth = Math.abs(f_text.aCoords?.br?.x - f_text.aCoords?.bl?.x)
              let totalTextHeight = Math.abs(f_text.aCoords?.bl?.y - f_text.aCoords?.tl?.y)
              var boothNameHeight = 0;
              boothNameHeight = boothNameHeightDefault * newScaleBooth * 1.1
              let newScaleCompany = 1
              var scaleX = (group.width - 2*marginX - diemensionFontSize)/totalTextWidth
              var scaleY = (group.height - 2*marginX - diemensionFontSize - boothNameHeight)/totalTextHeight
              newScaleCompany = Math.min(scaleX, scaleY)/1.1;

              var textWidth = totalTextWidth*newScaleCompany;
              var textHeight = totalTextHeight*newScaleCompany;
              var newLeft = 0 - textWidth/2 - fontSize * newScaleCompany*0.2
              var newTop = - group.height/2 + (group.height - boothNameHeight)/2 +marginX - textHeight/2
              if(f_text){
                f_text.set({      
                    scaleX: newScaleCompany,
                    scaleY: newScaleCompany,
                    textAlign:'center',       
                    left: newLeft, 
                    top:newTop,
                })
              }
            }
          }
          if(textobjects[j].class == "size"){
            var f_text = textobjects[j]
            // var boothNameHeight = 0;
            // boothNameHeight = f_text.height * newScaleBooth * 1.1
            // var textHeight = f_text.height*newScaleCompany;
            // var newLeft = 0 - group.width/2 + marginX
            // var newTop = 0 + group.height/2 - marginX*1 - textHeight - boothNameHeight
            if(f_text){
              f_text.set({      
                  // scaleX: newScaleBooth,
                  // scaleY: newScaleBooth,
                  // textAlign:'left',       
                  // left: newLeft, 
                  // top:newTop,
              })
            }
          }
        }
      }
    }
  }

  const adjustImage = ()=>{
    var convertRateX = 1;
    if(mainCanvas.convertRateX){
        convertRateX = mainCanvas.convertRateX
    }
    var fontSize = 50/Math.max(convertRateX, 1)
    var minFontSize = unit2Px((10-1)/3, convertRateX, mainCanvas.mapUnit)
    fontSize = Math.min(fontSize, minFontSize)
    var objects = step2Canvas.getObjects('group');
    for(var i = 0; i< objects.length; i++){
      {
        var group = objects[i];
        var images = objects[i].getObjects('image');
        for(var j = 0; j<  images?.length; j++){
          var image = images[j];
          var src = image.src;
          var imgObj = new Image();
          imgObj.crossOrigin = 'Anonymous';
          imgObj.onload = function(oImg) {
            var tempCanvas = document.createElement('CANVAS');
            var tempCtx = tempCanvas.getContext('2d');
            var height = tempCanvas.height = this.naturalHeight;
            var width = tempCanvas.width = this.naturalWidth;
            tempCtx.drawImage(this, 0, 0);
            var dataURL = tempCanvas.toDataURL();
            fabric.Image.fromURL(dataURL, function(img) {
              // console.log("convert src", img._element.currentSrc)
              image.setSrc(img._element.currentSrc)
            })
          }
          imgObj.src = src;
        }
      }
    }
  }
  const previewCallback = ()=>{    
    step2Canvas.renderAll();
    step2Canvas.requestRenderAll()

  }

  useEffect(() => {
    if(viewMode && step2Canvas){
      var groups = step2Canvas.getObjects('group');
      for(var i = 0; i< groups.length; i++){
        if(groups[i].class == "booth"){  
          // var pathObjects = groups[i].getObjects('polygon');
          // if(pathObjects && pathObjects.length>0){
          //   pathObjects[0].visible = showBoothOutline
          //   pathObjects[0].dirty = true
          // }
          // var rectObjects = groups[i].getObjects('rect');
          // if(rectObjects && rectObjects.length>0){
          //   rectObjects[0].visible = showBoothOutline
          //   rectObjects[0].dirty = true
          // }
          var textObjects = groups[i].getObjects('text');
          var font_size;
          var font_scale
          for(var j = 0; j < textObjects.length; j++){
            if(textObjects[j].get('class') != "size" && textObjects[j].get('class1') == 'boothName'){
              textObjects[j].visible = showBoothNumber
              textObjects[j].dirty = true
              font_size = textObjects[j].fontSize
              font_scale = textObjects[j].scaleX
            }
            if(textObjects[j].get('class') != "size" && textObjects[j].get('class1') == 'companyName'){
              textObjects[j].visible = showCompanyName
              textObjects[j].dirty = true
            }
            if(textObjects[j].get('class') == "size"){
              textObjects[j].visible = (props.main.authInfo?.role == 'admin' || 1)?showBoothSize:false
              textObjects[j].dirty = true
            }
          }
          // if(showBoothSize){
          //   if(font_size && font_scale){
          //     var f_text1 = new fabric.Text(groups[i].width.toFixed(0), {
          //       left: 0-groups[i].width.toFixed(0).length/2*font_size/2,
          //       top: 0+groups[i].height/2,
          //       fontSize:font_size/2,
          //       scaleX:font_scale,
          //       scaleY:font_scale,
          //       class:'size'
          //     });    
          //     groups[i].add(f_text1);
          //     var f_text2 = new fabric.Text(groups[i].height.toFixed(0), {
          //         left: 0+groups[i].width/2,
          //         top: 0,
          //         fontSize:font_size/2,
          //         scaleX:font_scale,
          //         scaleY:font_scale,
          //         class:'size'
          //     });    
          //     groups[i].add(f_text2);
          //   }
          // }
          // else{
          //   var textObjects = groups[i].getObjects('text');
          //   for(var j = 0; j < textObjects.length; j++){
          //     if(textObjects[j].get('class') == "size"){
          //       groups[i].remove(textObjects[j])
          //     }
          //   }
          // }
          // if(showCompanyName){
          //   var textObjects = groups[i].getObjects('text');
          //   var f_text;
          //   var f_text_hidden;
          //   for(var j = 0; j<  textObjects.length; j++){
          //     if(textObjects[j].class == "boothName"){
          //       if(textObjects[j].class1 == "hidden"){
          //           f_text_hidden = textObjects[j];
          //       }
          //       else{
          //           f_text = textObjects[j];
          //       }
          //     }
          //   }
          //   if(f_text){
          //     var tmp = f_text.tmp
          //     var company = groups[i].company
          //     var booth_number = groups[i].booth_number
          //     var str = (company?(company):"")+"\n"+booth_number
          //     if(tmp)
          //       f_text.set({text: tmp})
          //   }
          // }
          // else{
          //   var textObjects = groups[i].getObjects('text');
          //   var f_text;
          //   for(var j = 0; j<  textObjects.length; j++){
          //     if(textObjects[j].class == "boothName"){
          //       if(textObjects[j].class1 == "hidden"){
          //           f_text_hidden = textObjects[j];
          //       }
          //       else{
          //           f_text = textObjects[j];
          //       }
          //     }
          //   }
          //   if(f_text){
          //     var tmp = f_text.text
          //     var booth_number = groups[i].booth_number
          //     var str = "\n"+(booth_number?`${booth_number}`:"")
          //     f_text.set({text: str, tmp: (tmp?tmp:"")})
          //   }
          // }
          
          if(groups[i].class == "booth"){
            var priceCheck = groups[i].priceCheck;
            var holdBooth = groups[i].holdBooth;
            var sold = groups[i].sold
            var show = true;
            var exhibitors = groups[i].exhibitors
            var pending = (exhibitors?.[0].STATUS == '2')

            if(sold){
              if(showBoothColorSold){ 
              }
              else{     
                show = false
              }
            }
            else{
              if(priceCheck && priceCheck.length){
                if(showBoothColorAvailable){ 
                }
                else{     
                  show = false
                }
              }
            }
            
            if(holdBooth){
              if(showBoothColorHold){ 
              }
              else{     
                show = false
              }
            }

            if(pending){
              if(showBoothColorPending){ 
              }
              else{     
                show = false
              }
            }

            if(show){ 
              var bgColor = groups[i].tmpBg
              if(!bgColor){
                bgColor = '#dadada'
              }
              if(bgColor)
                setObjectBG(step2Canvas, groups[i], bgColor)
            }
            else{     
              var bgColor = getBGColor(groups[i])
              if(bgColor != '#dadada')
                groups[i].tmpBg = getBGColor(groups[i])   
              var bgColor = '#dadada'
              setObjectBG(step2Canvas, groups[i], bgColor)
            }

          } 
        }
      }
      step2Canvas.setZoom(step2Canvas.getZoom()*1.000001)
      step2Canvas.requestRenderAll();
    }
  }, [viewMode, showBoothNumber, showBoothSize, showCompanyName, showBoothColorSold, showBoothColorHold, showBoothColorAvailable, showBoothColorPending, valueFormat, showBG])

  const restoreCavas = ()=>{
    // var groups = props.main.mainCanvas.getObjects('group');
    // for(var i = 0; i< groups.length; i++){
    //   var pathObjects = groups[i].getObjects('polygon');
    //   if(pathObjects && pathObjects.length>0){
    //     pathObjects[0].visible = true
    //     pathObjects[0].dirty = true
    //   }
    //   var textObjects = groups[i].getObjects('text');
      
    //   for(var j = 0; j < textObjects.length; j++){
    //     if(textObjects[j].get('class') != "size" && textObjects[j].get('class1') != "hidden"){
    //       textObjects[j].visible = true
    //       textObjects[j].dirty = true
    //     }
    //   }
    //   var textObjects = groups[i].getObjects('text');
    //   for(var j = 0; j < textObjects.length; j++){
    //     if(textObjects[j].get('class') == "size"){
    //       groups[i].remove(textObjects[j])
    //     }
    //   }
    // }
    // props.redrawGrid()
    // props.main.mainCanvas.setZoom(props.main.mainCanvas.getZoom()*1.000001)
    // props.main.mainCanvas.requestRenderAll();
  }
  useEffect(() => {
    if(_sketch){
      var option = {format: 'png'}
      if(viewMode == "full"){
        option = {
          format: 'png',
          left:props.main.mainCanvas.viewportTransform[4]+45*props.main.mainCanvas.getZoom(),
          top:props.main.mainCanvas.viewportTransform[5]+45*props.main.mainCanvas.getZoom(),
          width:Math.abs(props.main.mainCanvas.endX-props.main.mainCanvas.startX)*props.main.mainCanvas.getZoom()+10,
          height:Math.abs(props.main.mainCanvas.endY-props.main.mainCanvas.startY)*props.main.mainCanvas.getZoom()+10
        }
      }
      
      var mainDataUrl = props.main.mainCanvas.toDataURL(option);
      let opts = {
        left: 0,
        top: 0,
        scale: 0.5,
      };
      _sketch.addImg(mainDataUrl, opts)
      //console.log(_sketch);
    }
  }, [_sketch])

  useEffect(() => {
    if(props.open){
      setStep(1)
      setTool(Tools.Pan)
      //console.log("canvas", props.main.mainCanvas)
    }
  }, [props.open])

  const dollyIn = ()=>{
    var zoom = step2Canvas.getZoom()
    zoom = zoom * 1.3;	
    step2Canvas.zoomToPoint({ x: step2Canvas.width/2, y: step2Canvas.height/2}, zoom);
    step2Canvas.requestRenderAll();
  }
  const dollyOut = ()=>{
    var zoom = step2Canvas.getZoom()
    zoom = zoom * 0.8;	
    step2Canvas.zoomToPoint({ x: step2Canvas.width/2, y: step2Canvas.height/2}, zoom);
    step2Canvas.requestRenderAll();
  }
  const swichFullView = ()=>{    
    var mapWidth = window.innerWidth;
    var mapHeight = window.innerHeight;
    if(mainCanvas.endX){
        var convertRateX= Math.abs(mainCanvas.convertRateX)
        var convertRateX= Math.abs(mainCanvas.convertRateX)
        mapWidth = Math.abs(mainCanvas.endX - mainCanvas.startX);
        mapHeight = Math.abs(mainCanvas.endY - mainCanvas.startY);
        if(mainCanvas.mapWidth && mainCanvas.mapWidth*1 && mainCanvas.mapHeight && mainCanvas.mapHeight*1){
          mapWidth = unit2Px(mainCanvas.mapWidth?mainCanvas.mapWidth:0, convertRateX, mainCanvas.mapUnit)
          mapHeight = unit2Px(mainCanvas.mapHeight?mainCanvas.mapHeight:0, convertRateX, mainCanvas.mapUnit)
        }
    }
    if(mapWidth && mapHeight){
        var zoomX = (700)/(mapWidth)
        var zoomY = (500)/(mapHeight)
        var zoom = Math.min(zoomX, zoomY)*0.95
        step2Canvas.viewportTransform[0] = zoom;
        step2Canvas.viewportTransform[3] = zoom;
        step2Canvas.viewportTransform[4] = 0;
        step2Canvas.viewportTransform[5] = 0;

        step2Canvas.requestRenderAll();
        if(Math.min(zoomX, zoomY) > 0){
            var deltaX = (800 - mapWidth*zoom)/2;
            var deltaY = (500 - mapHeight*zoom)/2;
            var delta = new fabric.Point(deltaX, 35);
            step2Canvas.relativePan(delta);
        }


    }
  }

  return (
    <div>
      <BootstrapDialog
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        // enforceFocus={false}
        className={"sketch"}
      >
        <BootstrapDialogTitle style={{display:'flex', alignItems:'center'}} id="customized-dialog-title" onClose={handleClose}>
          <SaveAltIcon style={{marginRight: 10}}/> Export
        </BootstrapDialogTitle>
        <DialogContent style={{paddingTop:'10px'}}>
          {((valueFormat == "image"  && step == 1) || valueFormat != "image") &&
          <>
            {(props.main.authInfo?.role == 'admin') &&
            <FormGroup>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">Format</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={valueFormat}
                  onChange={handleChangeFormat}
                >
                  <FormControlLabel value="dwg" control={<Radio />} label="DWG" />
                  <FormControlLabel value="pdf" control={<Radio />} label="PDF" />
                  {/* <FormControlLabel value="image" control={<Radio />} label="Image PNG" /> */}
                </RadioGroup>
              </FormControl>
            </FormGroup>
            }
            {/* <FormGroup>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">View mode</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={viewMode}
                  onChange={handleChangeViewMode}
                >
                  <FormControlLabel value="current" control={<Radio />} label="Current view" />
                  <FormControlLabel value="full" control={<Radio />} label="Full" />
                </RadioGroup>
              </FormControl>
            </FormGroup> */}
            <>
              {(valueFormat == "pdf" || valueFormat == "image") &&
              <>
                <FormLabel id="demo-controlled-radio-buttons-group">Show/Hide</FormLabel>
                <div>
                  <FormControl>
                    <FormGroup>  
                      <FormControlLabel control={
                          <Checkbox
                            checked={showCompanyName}
                            onChange={handleChangeCompanyName}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        } 
                        label="Company Name" 
                      />
                    </FormGroup>
                  </FormControl>
                  <FormControl>
                    <FormGroup>  
                      <FormControlLabel control={
                          <Checkbox
                            checked={showBG}
                            onChange={handleChangeBG}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        } 
                        label="Background Image" 
                      />
                    </FormGroup>
                  </FormControl>
                  <FormControl>
                    <FormGroup>  
                      <FormControlLabel control={
                          <Checkbox
                            checked={showBoothSize}
                            onChange={handleChangeBoothSize}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        } 
                        label="Booth Size" 
                      />
                    </FormGroup>
                  </FormControl>
                </div>
                <div>
                  <FormControl>
                    <FormGroup>  
                      <FormControlLabel control={
                          <Checkbox
                            checked={showBoothColorSold}
                            onChange={handleChangeBoothColorSold}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        } 
                        label="Sold Color" 
                      />
                    </FormGroup>
                  </FormControl>
                  <FormControl>
                    <FormGroup>  
                      <FormControlLabel control={
                          <Checkbox
                            checked={showBoothColorPending}
                            onChange={handleChangeBoothColorPending}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        } 
                        label="Pending Color" 
                      />
                    </FormGroup>
                  </FormControl>
                  <FormControl>
                    <FormGroup>  
                      <FormControlLabel control={
                          <Checkbox
                            checked={showBoothColorAvailable}
                            onChange={handleChangeBoothColorAvailable}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        } 
                        label="Available Color" 
                      />
                    </FormGroup>
                  </FormControl>              
                  <FormControl>
                    <FormGroup>  
                      <FormControlLabel control={
                          <Checkbox
                            checked={showBoothColorHold}
                            onChange={handleChangeBoothColorHold}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        } 
                        label="Reserved Color" 
                      />
                    </FormGroup>
                  </FormControl>
                </div>
              </>
              }
            </>
          </>
          }
          {(valueFormat == "image"  && step == 2) &&
          <Box>
            <Box mb={1}>
              <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Button className={tool==Tools.Pencil?"active":""} onClick={()=>setTool(Tools.Pencil)}><BorderColorIcon size="small"/></Button>
                <Button className={tool==Tools.Line?"active":""} onClick={()=>setTool(Tools.Line)}><RemoveOutlinedIcon size="small"/></Button>
                <Button className={tool==Tools.Arrow?"active":""} onClick={()=>setTool(Tools.Arrow)}><ArrowRightAltOutlinedIcon size="small"/></Button>
                <Button className={tool==Tools.Rectangle?"active":""} onClick={()=>setTool(Tools.Rectangle)}><RectangleOutlinedIcon size="small"/></Button>
                <Button className={tool==Tools.Circle?"active":""} onClick={()=>setTool(Tools.Circle)}><CircleOutlinedIcon size="small"/></Button>
                <Button className={tool==Tools.Pan?"active":""} onClick={()=>setTool(Tools.Pan)}><PanToolOutlinedIcon size="small"/></Button>
                <Button className={tool==Tools.Select?"active":""} onClick={()=>setTool(Tools.Select)}><PhotoSizeSelectSmallIcon size="small"/></Button>
                <Button onClick={(e) => _sketch.clearSelection()}><DeselectIcon size="small"/></Button>
                {/* <Button disabled={!(_sketch?_sketch.canUndo():false)} onClick={(e) => _sketch.undo()}><UndoIcon size="small"/></Button> */}
                <Button onClick={(e) => _sketch.zoom(1.25)}><ZoomInIcon size="small"/></Button>
                <Button onClick={(e) => _sketch.zoom(0.85)}><ZoomOutIcon size="small"/></Button>
                <Button onClick={(e) => _sketch.addText("Text")}><TextFormatIcon size="small"/></Button>
              </ButtonGroup>
            </Box>
            <SketchField             
              ref={(c) => setSketch(c)}
              width={850} 
              height={400} 
              tool={tool} 
              lineColor='red'
              lineWidth={2}/>
          </Box>
          }

          <Box>
            <Box mt={0}>
              <canvas id="step-2-canvas" width="800" height="500px"/>
              <div className="controls -ready import">
                  <Button title="Zoom In" onClick={()=>{dollyIn()}}><ZoomInIcon/></Button>
                  <Button style={{ marginTop: '5px' }}  title="Zoom Out" onClick={()=>{dollyOut()}}><ZoomOutIcon/></Button>
                  <Button style={{ marginTop: '5px' }}  title="Auto Fit on Screen" onClick={()=>{swichFullView()}}><FullscreenIcon/></Button>
              </div>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          {valueFormat == "dwg" &&
            <Button autoFocus onClick={()=>{exportDwgAPI('DWG'); }}>
              <b>Export</b>
            </Button>
          }
          {valueFormat == "pdf" &&
            <Button autoFocus onClick={()=>{exportDwgAPI('PDF'); }}>
              <b>Export</b>
            </Button>
          }
          {valueFormat == "image" && 
            <>     
              {step == 1 &&
              <Button autoFocus onClick={()=>{setStep(2); }}>
                <b>Next</b>
              </Button>
              }    
              {step > 1 &&
              <>
                <Button autoFocus onClick={()=>setStep(1)}>
                  <b>Back</b>
                </Button>
                <Button autoFocus onClick={()=>exportImage()}>
                  <b>Export</b>
                </Button>
              </>
              }
            </>
          }
        </DialogActions>
      </BootstrapDialog>
      <Snackbar
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
          }}
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleCloseSnack}
          message={snackMsg}
          action={
          <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
                  <CloseIcon fontSize="small" />
              </IconButton>
          </React.Fragment>
          }
      />
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    main: state.main,
    booth_history: state.booth_history.present
  };
};

const mapDispatchProps = (dispatch) => {
  return {    
    setLoading:  data=>dispatch(setLoading(data)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(ExportFile);
