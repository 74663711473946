
import {
  SET_SEARCH_KEY_SELECT,
  SET_SEARCH_OPTIONS,
  SET_SEARCH_PULLDOWN_VALUE,
  SET_SEARCH_INPUT_VALUE
} from '../actions/searchbox';

var initVal = {
  searchKeySelect:'',
  searchOptions:[],
  searchPulldownValue:'',
  searchInputValue:''
}
export default function searchbox(state = initVal, {type, payload}) {
  switch (type) {
    case SET_SEARCH_KEY_SELECT:
      return {
        ...state,
        searchKeySelect: payload
    }
    case SET_SEARCH_OPTIONS:
      return {
        ...state,
        searchOptions: payload
    }
    case SET_SEARCH_PULLDOWN_VALUE:
      return {
        ...state,
        searchPulldownValue: payload
    }
    case SET_SEARCH_INPUT_VALUE:
      return {
        ...state,
        searchInputValue: payload
    }
    default:
        return state;
  }
}