import * as React from 'react';
import { useEffect, useRef } from 'react';
import { connect } from 'react-redux'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import SearchIcon from '@mui/icons-material/Search';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { makeStyles } from '@mui/styles';
import { setSearchKeySelect, setSearchOptions, setSearchPulldownValue, setSearchInputValue} from '../../actions/searchbox'
import { getTokenCookie } from '../../utils/Common'
import { ESHOW_URL } from '../../config.js'
import axiosInstance from '../../utils/axiosInstance.js'


function SearchBox(props) {
  const keywordInput = useRef(null);
  const selectRef = useRef(null);

  const useStyles = makeStyles((theme) => ({
    searchBtn:{
      backgroundColor: props.ui?.customStyles?.['search-button-bg-color']?(props.ui?.customStyles['search-button-bg-color']+"!important"):'#1565c0',
      color: props.ui?.customStyles?.['search-button-label-color']?(props.ui?.customStyles['search-button-label-color']+"!important"):'#1565c0',
    },
    bubble:{
      color:props.ui?.customStyles?.['bubble-label-color']?(props.ui?.customStyles['bubble-label-color']+"!important"):'white',
      backgroundColor:props.ui?.customStyles?.['bubble-bg-color']?(props.ui?.customStyles['bubble-bg-color']+"!important"):'#1565c0',
    }
  }));
  const classes = useStyles();  
  let standardSearchOptions = props.main.exhibitorList?.SEARCH?.STANDARD??[]
  let advancedSearchOptions = props.main.exhibitorList?.SEARCH?.ADVANCED??[]
  var keySelect = props.searchbox.searchKeySelect
  var pulldownValue = props.searchbox.searchPulldownValue
  var searchInputValue = props.searchbox.searchInputValue


  
  useEffect(() => {      
      props.setSearchKeySelect('')
  }, [])  

  useEffect(() => {
    if(standardSearchOptions && standardSearchOptions.length){
      if(keySelect == '')
        props.setSearchKeySelect(standardSearchOptions[0].LABEL)
    }
  }, [standardSearchOptions, advancedSearchOptions])  

  const resetSearch=()=>{
    if(keywordInput?.current)
      keywordInput.current.value = ''
    if(selectRef?.current)
      selectRef.current.value = ''
    props.resetSearch()
  }

  const getSearchOption = (value)=>{
    console.log("standardSearchOptions", standardSearchOptions)
    if (!value) return false;
    for(var i = 0; i < standardSearchOptions?.length; i++){
      if(standardSearchOptions[i].LABEL == value){
        return standardSearchOptions[i]
      }
    }
    return false
  }

  let searchOption = getSearchOption(keySelect)
  let dropdownOptions = [];
  if(searchOption && searchOption.TYPE == 'pulldown'){
    if(searchOption.ID == 'question'){
      for(var i = 0; i < searchOption.ITEMS?.length; i++){
        let title = searchOption.ITEMS[i].answer_title
        if(title === true){
          title = "Yes"
        }
        if(title === false){
          title = "No"
        }
        dropdownOptions.push(
          {
            value:`${searchOption.ITEMS[i].answer_key}`?.trim(),
            label:`${title}`?.trim()+(searchOption.ITEMS[i].qty?(` (${searchOption.ITEMS[i].qty})`):""),
          }
        )
      }
    }
    else{
      for(var i = 0; i < searchOption.ITEMS?.length; i++){
        var key = false
        if(searchOption.ITEMS[i].VALUE){
          key = searchOption.ITEMS[i].VALUE
        }
        else if(searchOption.ITEMS[i].ID){
          key = searchOption.ITEMS[i].ID
        }
        if(key){
          dropdownOptions.push(
            {
              value:`${key}`?.trim(),
              label:`${searchOption.ITEMS[i].TITLE}`?.trim()+(searchOption.ITEMS[i].QTY?(` (${searchOption.ITEMS[i].QTY})`):""),
            }
          )
        }
      }
    }
  }

  console.log("standardSearchOptions", standardSearchOptions)
  return (
    <Paper
      className='search-bar'
      component="form"
      sx={{ p: '0px 0px', display: 'flex', alignItems: 'center', maxWidth: '750px' }}
    >
      <FormControl variant="outlined" style={{width:'170px', border:'none'}}>
        <Select
          className='key-select'
          style={{border:'none', outline:'none'}}
          // displayEmpty
          value={keySelect?keySelect:'Keyword'}
          onChange={(e)=>{
            props.setSearchKeySelect(e.target.value); props.setSearchPulldownValue(false);
            if(keywordInput?.current)
              keywordInput.current.value = ''
            if(selectRef?.current)
              selectRef.current.value = ''
          }}
        >
          {
            standardSearchOptions.map((searchOption, index)=>{
              return(
              <MenuItem value={searchOption.LABEL} key={index}>{searchOption.LABEL}</MenuItem>
              )
            })
          }
        </Select>
      </FormControl>
      
      <Divider sx={{ height: '32px', m: 0.5 }} orientation="vertical" />
      
      {searchOption.TYPE == 'pulldown' ?
        <Select
          className='key-select'
          sx={{ flex: 1, width:300,border:'none', outline:'none', textAlign:'left'  }}
          // displayEmpty
          ref={selectRef}
          value = {pulldownValue}
          onChange={(e)=>{props.setSearchPulldownValue(e.target.value)}}
        >
          {
            dropdownOptions.map((dropdownOption, index)=>{
              return(
                <MenuItem value={dropdownOption.value} key={index}>{dropdownOption.label}</MenuItem>
              )
            })
          }
        </Select>:
        <InputBase
          sx={{ flex: 1, width:300  }}
          placeholder="Search by keyword"
          inputProps={{ 'aria-label': 'Search by keyword' }}
          onChange={(e)=>{props.setSearchInputValue(e.target.value)}}         
          inputRef={keywordInput}
        />
      }
      {searchOption.TYPE != 'pulldown' &&
      <IconButton aria-label="delete" onClick={resetSearch} style={{padding:0, marginRight:5}}>
        <HighlightOffIcon  sx={{ p: '5px' }} />
      </IconButton>
      }
      {searchOption.TYPE != 'pulldown' &&
      <Button onClick={(()=>resetSearch())} className={`${classes.searchBtn} search-attach` } variant="contained" color="primary" sx={{ p: '10px' }} aria-label="directions">
        <SearchIcon sx={{ p: '5px' }} />
        Clear
      </Button>
      }
    </Paper>
  )
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
      ui: state.ui,
      searchbox: state.searchbox
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
      setSearchOptions:  data=>dispatch(setSearchOptions(data)),
      setSearchKeySelect:  data=>dispatch(setSearchKeySelect(data)),
      setSearchPulldownValue:  data=>dispatch(setSearchPulldownValue(data)),
      setSearchInputValue:  data=>dispatch(setSearchInputValue(data)),
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(SearchBox);