import * as React from 'react';
import { useEffect, useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FileUpload from '@mui/icons-material/CloudUpload';
import BackupIcon from '@mui/icons-material/Backup';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import LayersIcon from '@mui/icons-material/Layers';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import { API_URL } from '../../config.js'
import axios from 'axios';
import qs, { parse } from 'qs'
import { SVG, extend as SVGextend, Element as SVGElement } from '@svgdotjs/svg.js'
import { Canvg } from 'canvg';
import { setMainLayers, setBgInfo, setImportLayers, setLoading, setSaveFileName, resetMain, setBoothFileList,setMainSvg } from '../../actions/main'
import { setOpenFilenameModal, resetUI } from '../../actions/ui'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ScaleLoader from 'react-spinners/ScaleLoader'
import LoadingOverlay from 'react-loading-overlay';
import { fabric } from "fabric";
import FilenameModal from "./FilenameModal"
import { v4 as uuidv4 } from 'uuid';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import {PaperComponent, fetchWithXMLHttpRequest} from '../../utils/Common'


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <LayersIcon/>,
    2: <CloudUploadOutlinedIcon/>,
    3: <InsertPhotoOutlinedIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};
const steps = ['Select Layer', 'Select Background Source', 'Final'];
var lastPosX = 0;
var lastPosY = 0;
var isDragging = false

var importCanvas = null;
var step2Canvas = null;
var step2BgCanvas = null;
function Index(props) {
  const { onClose, onOk, onChange, open } = props;
  const [step, setStep] = React.useState(1);
  const [filetype, setFiletype] = React.useState("dwg");
  const [imgUrl, setImageUrl] = React.useState(null);
  const [dwgSvg, setDwgSvg] = React.useState(null);
  const [bgLayers, setBgLayers] = React.useState([])
  const [imageWidth, setImageWidth] = React.useState(3000)
  const [imageHeight, setImageHeight] = React.useState(3000)
  const [dwgWidth, setDwgWidth] = React.useState(3000)
  const [dwgHeight, setDwgHeight] = React.useState(3000)
  const [factor, setFactor] = React.useState(1)
  const [prepareDwgimg, setPrepareDwgimg] = React.useState(false)
  const [preparePreviewDwgimg, setPreparePreviewDwgimg] = React.useState(false)  
  const [offsetX, setOffsetX] = React.useState(0)
  const [offsetY, setOffsetY] = React.useState(0)
  const [expand_bg, setExpandBg] = React.useState(false)
  const [expand_outline, setExpandOutline] = React.useState(false)
  const [expand_number, setExpandNumber] = React.useState(false)
  const [expand_banquet, setExpandBanquet] = React.useState(false)
  const [expand_val, setExpandVal] = React.useState(false)
  const [importLayers, setMoldalImportLayers] = React.useState([])
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [importSvg, setImportSvg] = React.useState(false)
  const [mapUnit, setMapUnit] = React.useState("feet");

  const openRef1 = useRef();
  const openRef2 = useRef();

  const mainSvg = props.main.mainSvg;
  const renderWidth = 625;
  const renderHeight = 500;
  
  const rate = ()=>{
    console.log("renderWidth", renderWidth)
    console.log("dwgWidth", dwgWidth)
    if(dwgWidth)
      return renderWidth/dwgWidth;
    else 
      return 1;
  }


  useEffect(() => {
    if(open){
      setImageUrl(null)
      setDwgSvg(null)
      setFactor(1)
      setOffsetX(0)
      setOffsetY(0)
      setPrepareDwgimg(false)
      setStep(1)
      setExpandOutline(false)
      setExpandNumber(false)
      setExpandBg(false)
      setExpandVal(false)
      props.setMainSvg(false)
    }
  }, [open])

  useEffect(() => {    
    props.setLoading(true)
    setTimeout(function(){
      var tmp = [...props.main.importLayers];
      console.log("imported Layers", tmp)
      var number_selected = false;
      var outline_selected = false
      for(var i = 0; i <tmp.length; i++){
        var name = tmp[i]['name']+"";
        name = name.toLowerCase();
        if(name.includes("expo") && name.includes("booth") && name.includes("outline")){
          tmp[i] = {...tmp[i], outline_selected: true, selected: true};
          outline_selected = true
        }
        else if(name.includes("expo") && name.includes("booth") && name.includes("number")){
          tmp[i] = {...tmp[i], number_selected: true, selected: true};
          number_selected = true
        }
        else if(name.includes("furniture") || name.includes("banquet")){
          tmp[i] = {...tmp[i], banquet_selected: true, selected: true};
        }
        else if(name.includes("venue")){
          tmp[i] = {...tmp[i], venue_selected: true, selected: true};
        }
        else{
          tmp[i] = {...tmp[i], selected: false};
        }
      }
      if(!number_selected){
        for(var i = 0; i <tmp.length; i++){
          var name = tmp[i]['name']+"";
          name = name.toLowerCase();
          if(name.includes("booth") && name.includes("number")){
            tmp[i] = {...tmp[i], number_selected: true, selected: true};
            number_selected = true
          }
        }
      }
      if(!outline_selected){
        for(var i = 0; i <tmp.length; i++){
          var name = tmp[i]['name']+"";
          name = name.toLowerCase();
          if(name.includes("booth") && name.includes("outline")){
            tmp[i] = {...tmp[i], outline_selected: true, selected: true};
            outline_selected = true
          }
        }
      }

      setMoldalImportLayers(tmp)
    }, 100)
  }, [props.main.importLayers])

  const initBoothFileList=()=>{     
    // var boothFileList = props.main.boothFileList;   
    // var tmp = [...boothFileList];
    // for(var i = 0; i <tmp.length; i++){
    //     tmp[i] = {...tmp[i], active:false}
    // }
    // props.setBoothFileList(tmp)
  }


  const getSvgInfo=(svgStr)=>{
    var drawermain = SVG(svgStr);
    var dimension_str = drawermain.find("#cadviewer_drawingCoordinates").text();
    var units_str = drawermain.find("#cadviewer_worldUnits").text();
    //console.log("dimension_str", dimension_str)
    var dimension = null;
    var convertRateX = 0;
    var convertRateY = 0;
    var convertOffsetX = 0;
    var convertOffsetY = 0;
    var startX = 0;
    var startY = 0;
    var endX = 0;
    var endY = 0;
    var mapWidth = 0;
    var mapHeight = 0;
    var convertUnit;
    if(units_str && units_str.length>0){
        units_str[0] = units_str[0].replaceAll(". ", "");
        var units = JSON.parse(units_str[0]);
        var globalOrigin = units['globalOrigin']
        if(globalOrigin['units']){
            convertUnit = globalOrigin['units']
        }
        else{
            convertUnit = units['units']
        }
    }
    if(dimension_str && dimension_str.length>0){      
        dimension_str[0] = dimension_str[0].replaceAll(". ", "");
        dimension = JSON.parse(dimension_str[0])
        //console.log("dimension", dimension)
        var dwg_info = dimension.DWG;
        var svg_info = dimension.SVG;
        convertOffsetX = svg_info.lowerLeft.x - dwg_info.lowerLeft.x
        convertOffsetY = svg_info.upperRight.y - dwg_info.upperRight.y
        var width_svg = svg_info.upperRight.x - svg_info.lowerLeft.x
        var height_svg = svg_info.upperRight.y - svg_info.lowerLeft.y
        var width_dwg = dwg_info.upperRight.x - dwg_info.lowerLeft.x
        var height_dwg = dwg_info.upperRight.y - dwg_info.lowerLeft.y
        convertRateX = width_dwg/width_svg;
        convertRateY = height_dwg/height_svg;
        startX = svg_info.lowerLeft.x;
        startY = svg_info.lowerLeft.y;
        endX = svg_info.upperRight.x;
        endY = svg_info.upperRight.y;
        if(convertUnit){
            if(convertUnit == 'in'){
                mapWidth = width_dwg/12;
                mapHeight = height_dwg/12;
            }
            // if(convertUnit == 'mm'){
            //   convertRateX*=1000
            // }
        }
    }

    return {
        convertRateX, convertRateY, convertOffsetX, convertOffsetY, mapWidth, mapHeight, convertUnit
    };
  }

  useEffect(async () => {    
    setBgLayers([...importLayers])
    if(importLayers && importLayers.length> 0 && mainSvg && step == 1){
      if(document.getElementById("step-1-canvas")){

        // document.getElementById("step-1-canvas").innerHTML = ""
        var drawermain = SVG(mainSvg);
        var bbox = drawermain.bbox();
        var width = bbox.width.toFixed(1)
        var height = bbox.height.toFixed(1)
        setImageWidth(width)
        setImageHeight(height)
        setDwgWidth(width)
        setDwgHeight(height)
        drawermain.width(document.getElementById("step-1-canvas").offsetWidth)
        drawermain.height(renderHeight)
        drawermain.viewbox(0,0,width,height)
        drawermain.transform({
          a:1,b:0,c:0,d:1,e:0,f:0
        })
        var {convertRateX, convertRateY, convertOffsetX, convertOffsetY, convertUnit} = getSvgInfo(mainSvg);
        // drawermain.addTo('.step-1-canvas')
        for(var layerInd = 0; layerInd< importLayers.length; layerInd++){
          var layer = importLayers[layerInd];
          var topgroup = drawermain.find("#"+layer['id']);
          if(layer && !layer.outline_selected  && !layer.number_selected  && !layer.banquet_selected && !layer.venue_selected  && !layer.bg_selected){
              if(topgroup && topgroup.length > 0){
                topgroup.remove();
              }
          }
          if(layer && layer.bg_selected){
            var subGroups = topgroup.find('g');
            if(subGroups && subGroups.length > 0){
              for(var i = 0; i< subGroups[0].length; i++){
                if(subGroups[0][i].node.style.fill!= ''){
                  subGroups[0][i].node.style.fill="transparent"
                }
                // subGroups[0][i].node.style.fill="transparent"
              }
            }
          }
          if(layer && layer.outline_selected){
            var subGroups = topgroup.find('g');
            if(subGroups && subGroups.length > 0){
              for(var i = 0; i< subGroups[0].length; i++){
                subGroups[0][i].node.style.fill="transparent"
                subGroups[0][i].node.style.stroke="black"
              }
            }
          }
          var textelements = topgroup.find('text');
          if(textelements && textelements.length > 0){
            for(var i = 0; i< textelements[0].length; i++){
              if(textelements[0][i].node.innerHTML == "For Demonstration Purposes Only"){
                textelements[0][i].remove();
              }
              if(textelements[0][i].node.innerHTML == "Tailor Made Software, Ltd"){
                textelements[0][i].remove();
              }
              if(textelements[0][i].node.innerHTML == "www.cadviewer.com"){
                textelements[0][i].remove();
              }
              if(textelements[0][i].node.innerHTML == "www.tailormade.com"){
                textelements[0][i].remove();
              }
            }
          }
        }
        // drawermain.find("#cadviewer_drawingCoordinates").remove();
        // drawermain.find("#cadviewer_worldUnits").remove();
        // drawermain.find("#cadviewer_vqBuilding").remove();
        // drawermain.find("#cadviewer_vqFloor").remove();
        // drawermain.find("#cadviewer_multiPages").remove();
        var svgstring = drawermain.svg();
        svgstring = svgstring.replace(/&quot;/g,"'");
        svgstring = svgstring.replace(/svgjs:/g,"");

        fabric.Object.prototype.objectCaching = true
        importCanvas = new fabric.Canvas("step-1-canvas");
        importCanvas.set({
          selection:false
        })
        importCanvas.requestRenderAll();
        
        var svgURL = 'data:image/svg+xml; charset=utf8, ' + encodeURIComponent(svgstring);
        // var fabricpath = fabric.loadSVGFromString(svgstring,function(objects, options) {
        fabric.Image.fromURL(svgURL, function(img) {
          // var obj = fabric.util.groupSVGElements(objects, options);
          // // obj.scaleToHeight(mainCanvas.height-10)
          //     // .set({ left: mainCanvas.width/2, top: mainCanvas.height/2 })
          // console.log("obj", obj)
          img.set({              
            left: 0, 
            top: 0, 
            selectable: false, 
            selection: false, 
            hasControls: false, 
            hasBorders: false, 
            evented: false, 
            objectCaching : true
          })
          // var objects = obj.getObjects();
          // for(var i =0; i< objects.length; i++){
          //   objects[i].set({   
          //     strokeWidth: 3.5* objects[i].strokeWidth / convertRateX, 
          //     left: objects[i].left+(3.5* objects[i].strokeWidth / convertRateX),
          //     top: objects[i].top+(3.5* objects[i].strokeWidth / convertRateX),
          //     objectCaching : false
          //   })
          // }
          // .setCoords();
          try{
            importCanvas.add(img)
            importCanvas.renderAll();
          }
          catch(error){
            console.log(error)
            alert("Reselect layer!");
          }

          importCanvas.off('mouse:down');
          importCanvas.on('mouse:down', function(opt) { 
            importCanvas.isDragging = true           
              isDragging = true;
              var evt = opt.e;
                lastPosX = evt.clientX;
                lastPosY = evt.clientY;
          });

          importCanvas.off('mouse:up');
          importCanvas.on('mouse:up', function(opt) {
            importCanvas.setViewportTransform(importCanvas.viewportTransform);
              isDragging = false;
              importCanvas.isDragging = false           
          });

          importCanvas.off('mouse:move')    
          importCanvas.on('mouse:move', function(opt) {
              this.isMoving = true;
              if (isDragging) {
                var e = opt.e;
                var vpt = importCanvas.viewportTransform;
                vpt[4] += e.clientX - lastPosX;
                vpt[5] += e.clientY - lastPosY;
                importCanvas.requestRenderAll();
                lastPosX = e.clientX;
                lastPosY = e.clientY;
                return;
              }
          });
          setTimeout(function(){
            props.setLoading(false)
          }, 200)
          
        });
      }
    }
    setPrepareDwgimg(false)
  },[importLayers, prepareDwgimg])

  useEffect( async () => {
    if(prepareDwgimg && mainSvg && factor){
      if(document.getElementById("render-booth-container")){
        var drawermain = SVG(mainSvg);
        var bbox = drawermain.bbox();
        var width = bbox.width.toFixed(1)
        var height = bbox.height.toFixed(1)
        drawermain.width(document.getElementById("render-booth-container").offsetWidth)
        drawermain.height(renderHeight)
        drawermain.viewbox(0,0,width,height)
        drawermain.transform({
          a:1,b:0,c:0,d:1,e:0,f:0
        })
        // document.getElementById("step-2-canvas").innerHTML = ""

        // drawermain.addTo('.step-2-canvas') //preview svg 
        for(var layerInd = 0; layerInd< bgLayers.length; layerInd++){
          var layer = bgLayers[layerInd];
          var topgroup = drawermain.find("#"+layer['id']);
          if(layer && !layer.outline_selected && !layer.number_selected && !layer.banquet_selected && !layer.venue_selected){
              if(topgroup && topgroup.length > 0){
                topgroup.remove();
              }
          }
          var textelements = topgroup.find('text');
          if(textelements && textelements.length > 0){
            for(var i = 0; i< textelements[0].length; i++){
              if(textelements[0][i].node.innerHTML == "For Demonstration Purposes Only"){
                textelements[0][i].remove();
              }
              if(textelements[0][i].node.innerHTML == "Tailor Made Software, Ltd"){
                textelements[0][i].remove();
              }
              if(textelements[0][i].node.innerHTML == "www.cadviewer.com"){
                textelements[0][i].remove();
              }
              if(textelements[0][i].node.innerHTML == "www.tailormade.com"){
                textelements[0][i].remove();
              }
            }
          }
        }
        var svgstring = drawermain.svg();
        svgstring = svgstring.replace(/&quot;/g,"'");
        svgstring = svgstring.replace(/svgjs:/g,"");
        if(document.getElementById("step-2-canvas")){
          step2Canvas = new fabric.StaticCanvas("step-2-canvas");
  
          if(filetype == "dwg" && document.getElementById("render-booth-container")){
            var drawermain = SVG(mainSvg);
            var bbox = drawermain.bbox();
            var width = bbox.width.toFixed(1)
            var height = bbox.height.toFixed(1)
            setImageWidth(width)
            setImageHeight(height)
            drawermain.width(document.getElementById("render-booth-container").offsetWidth)
            drawermain.height(renderHeight)
            drawermain.viewbox(0,0,width,height)
            // drawermain.transform({
            //   a:factor,b:0,c:0,d:factor,e:width*(factor-1)/2*renderWidth/width,f:height*(factor-1)/2*renderWidth/width
            // })
    
            // document.getElementById("step-2-bg-canvas").innerHTML = ""
            // drawermain.addTo('.step-2-bg-canvas') //preview svg 
            for(var layerInd = 0; layerInd< bgLayers.length; layerInd++){
              var layer = bgLayers[layerInd];
              var topgroup = drawermain.find("#"+layer['id']);
              if(layer && !layer.outline_selected  && !layer.number_selected  && !layer.banquet_selected && !layer.venue_selected  && !layer.bg_selected){
                if(topgroup && topgroup.length > 0){
                  topgroup.remove();
                }
              }
              if(layer && layer.bg_selected){
                var subGroups = topgroup.find('g');
                if(subGroups && subGroups.length > 0){
                  for(var i = 0; i< subGroups[0].length; i++){
                    if(subGroups[0][i].node.style.fill!= ''){
                      subGroups[0][i].node.style.fill="transparent"
                    }
                  }
                }
              }
              
              if(layer && layer.outline_selected){
                var subGroups = topgroup.find('g');
                if(subGroups && subGroups.length > 0){
                  for(var i = 0; i< subGroups[0].length; i++){
                    // subGroups[0][i].node.style.fill="transparent"
                    subGroups[0][i].node.style.stroke="black"
                  }
                }
              }
              var textelements = topgroup.find('text');
              if(textelements && textelements.length > 0){
                for(var i = 0; i< textelements[0].length; i++){
                  if(textelements[0][i].node.innerHTML == "For Demonstration Purposes Only"){
                    textelements[0][i].remove();
                  }
                  if(textelements[0][i].node.innerHTML == "Tailor Made Software, Ltd"){
                    textelements[0][i].remove();
                  }
                  if(textelements[0][i].node.innerHTML == "www.cadviewer.com"){
                    textelements[0][i].remove();
                  }
                  if(textelements[0][i].node.innerHTML == "www.tailormade.com"){
                    textelements[0][i].remove();
                  }
                }
              }
            }
            var svgstringbg = drawermain.svg();
            svgstringbg = svgstringbg.replace(/&quot;/g,"'");
            svgstringbg = svgstringbg.replace(/svgjs:/g,"");
            fabric.loadSVGFromString(svgstringbg,function(objects, options) {
              var obj = fabric.util.groupSVGElements(objects, options);
              // obj.scaleToHeight(mainCanvas.height-10)
                  // .set({ left: mainCanvas.width/2, top: mainCanvas.height/2 })
              obj.set({
                  left: (offsetX?offsetX:0)*rate(), 
                  top: (offsetY?offsetY:0)*rate(), 
                  scaleX: factor,
                  scaleY: factor,
                  class: 'bg'
              })
              .setCoords();
              step2Canvas.add(obj)
              step2Canvas.renderAll();
            });
            
    
          }

          // var svgURL = 'data:image/svg+xml; charset=utf8, ' + encodeURIComponent(svgstring);
          var fabricpath = fabric.loadSVGFromString(svgstring,function(objects, options) {
          // fabric.Image.fromURL(svgURL, function(img) {
            var obj = fabric.util.groupSVGElements(objects, options);
            // // obj.scaleToHeight(mainCanvas.height-10)
            //     // .set({ left: mainCanvas.width/2, top: mainCanvas.height/2 })
            obj.set({
                
              left: 0, 
              top: 0, 
            })
            .setCoords();
            step2Canvas.add(obj)
            step2Canvas.renderAll();
            props.setLoading(false);
          });
        }
      }

      setPrepareDwgimg(false)
    }
  }, [prepareDwgimg, mainSvg])

  useEffect( async () => {
    if(step2Canvas){
      if(filetype== "pdf" || filetype== "image"){
        step2Canvas.setBackgroundImage(imgUrl, step2Canvas.renderAll.bind(step2Canvas), {
          opacity: 0.5,
          angle: 0,
          left: (offsetX?offsetX:0)*rate(), 
          top: (offsetY?offsetY:0)*rate(), 
          originX: 'left',
          originY: 'top',
          scaleX: rate()*factor,
          scaleY: rate()*factor,
          crossOrigin: 'anonymous',
        });
      }
      else{
        var objects = step2Canvas.getObjects();
        for(var i = 0; i < objects.length; i++){
          if(objects[i].get('class') == 'bg'){
            objects[i].set({
              left: (offsetX?offsetX:0)*rate(), 
              top: (offsetY?offsetY:0)*rate(), 
              scaleX: factor,
              scaleY: factor
            })          
            step2Canvas.renderAll();
            break;
          }
        }
      }
    }
  }, [factor, offsetX, offsetY])

  useEffect(() => {
    if(step == 1){      
      props.setLoading(true);
      setTimeout(function(){
        setPrepareDwgimg(true)
      }, 100)
    }
    if(step == 2){      
      props.setLoading(true);
      setTimeout(function(){
        setPrepareDwgimg(true)
      }, 100)
    }
    // if(step == 3){
    //   props.setLoading(true);
    //   setTimeout(function(){
    //     setPrepareDwgimg(true)
    //   }, 100)
    // }
  }, [step, open])
  
  const onSet = async ()=>{
    console.log("start import", Math.floor(Date.now() / 1000))
    initBoothFileList()
        
    // props.resetUI();
    // props.resetMain();

    var mainCanvas = props.main.mainCanvas
    if(props.main.mainCanvas){
      var group_objects = mainCanvas.getObjects('group');
      for(var j = 0; j < group_objects.length; j++){
          var group_object = group_objects[j]
          props.main.mainCanvas.remove(group_object);
      }    
      mainCanvas.clear();   
      if(props.main.bgCanvas) {
        props.main.bgCanvas.clear();  
        props.main.bgCanvas.viewportTransform[0] = 1;
        props.main.bgCanvas.viewportTransform[3] = 1;
        props.main.bgCanvas.viewportTransform[4] = 0;
        props.main.bgCanvas.viewportTransform[5] = 0;
      }
      if(props.main.bgImgCanvas) {
        props.main.bgImgCanvas.clear();  
        props.main.bgImgCanvas.viewportTransform[0] = 1;
        props.main.bgImgCanvas.viewportTransform[3] = 1;
        props.main.bgImgCanvas.viewportTransform[4] = 0;
        props.main.bgImgCanvas.viewportTransform[5] = 0;
      }
      if(props.main.g_grid) 
        props.main.g_grid.clear()          
      mainCanvas.viewportTransform[0] = 1;
      mainCanvas.viewportTransform[3] = 1;
      mainCanvas.viewportTransform[4] = 0;
      mainCanvas.viewportTransform[5] = 0;

      mainCanvas.set({mapUnit:mapUnit})
      props.main.mainCanvas.requestRenderAll()
      
      document.getElementsByClassName("canvas-container")[0].style.display = 'none';
    }
    handleCloseFilenameModal()
    if(!props.main.saveFileName) return;
    props.setLoading(true);
    setTimeout(async function(){      
      var mainCanvas = props.main.mainCanvas;
      var {convertRateX, convertRateY, convertOffsetX, convertOffsetY, convertUnit} = getSvgInfo(mainSvg);
      var tmp_main = SVG(mainSvg); // real svg to used as main canvas background
      if(0){
        for(var layerInd = 0; layerInd< bgLayers.length; layerInd++){
          var layer = bgLayers[layerInd];
          var topgroup = tmp_main.find("#"+layer['id']);
          if(layer && !layer.venue_selected){
              if(topgroup && topgroup.length > 0){
                topgroup.remove();
              }
          }
          
          if(layer && layer.venue_selected){
            var subGroups = topgroup.find('g');
            if(subGroups && subGroups.length > 0){
              for(var i = 0; i< subGroups[0].length; i++){
                if(subGroups[0][i].node.style.fill!= ''){
                  subGroups[0][i].node.style.fill="transparent"
                }

                const computedStyle = window.getComputedStyle(subGroups[0][i].node);
                const strokeWidth = computedStyle.getPropertyValue('stroke-width');

                console.log("strokeWidth", strokeWidth)
                subGroups[0][i].node.style.stroke="black"
                let idealWidth = 2; //2feet
                  if(mapUnit=='meter'){
                    idealWidth = 2/20 //10cm
                    if(convertUnit == 'mm'){
                      idealWidth = 20 //20cm
                    }
                  }
                  subGroups[0][i].node.style.strokeWidth=(strokeWidth?strokeWidth:1)*Math.min(idealWidth/(convertRateX?convertRateX:1),10)
              }
            }
          }
          var textelements = topgroup.find('text');
          if(textelements && textelements.length > 0){
            for(var i = 0; i< textelements[0].length; i++){
              if(textelements[0][i].node.innerHTML == "For Demonstration Purposes Only"){
                textelements[0][i].remove();
              }
              if(textelements[0][i].node.innerHTML == "Tailor Made Software, Ltd"){
                textelements[0][i].remove();
              }
              if(textelements[0][i].node.innerHTML == "www.cadviewer.com"){
                textelements[0][i].remove();
              }
              if(textelements[0][i].node.innerHTML == "www.tailormade.com"){
                textelements[0][i].remove();
              }
            }
          }
        }
        var svgstring = tmp_main.svg();
        svgstring = svgstring.replace(/&quot;/g,"'");
        svgstring = svgstring.replace(/svgjs:/g,"");
          

        var data = svgstring
        var filePath = `map_bg/${uuidv4()}`
        var url = API_URL()+`uploadbgimg?ax-allow-ext=all&listtype=serverfolder&filePath=${filePath}&&start=0`

        try {
          let objecturl = await fetchWithXMLHttpRequest(url, 'POST', data)
          props.setBgInfo({
            column_url:objecturl,
          })
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }

      // var xhr1 = new XMLHttpRequest();
      // xhr1.open("POST", url);

      // xhr1.setRequestHeader("Accept", "image/apng");

      // xhr1.onreadystatechange = function () {
      //   if (xhr1.readyState === 4) {
      //       console.log(xhr1.responseText);
      //       var objecturl = xhr1.responseText
      //       props.setBgInfo({
      //         column_url:objecturl,
      //       })
      //   }
      // };

      // xhr1.send((data));


      if(filetype == "image" || filetype == "pdf"){
        if(imgUrl){
          // mainCanvas.setBackgroundImage(imgUrl, mainCanvas.renderAll.bind(mainCanvas), {
          //   opacity: 0.5,
          //   angle: 0,
          //   left: offsetX*1+50,
          //   top: offsetY*1+50,
          //   originX: 'left',
          //   originY: 'top',
          //   scaleX: factor,
          //   scaleY: factor,
          //   crossOrigin: 'anonymous'
          // });
          props.setBgInfo({
            bg_url:imgUrl,
            bg_width:imageWidth,
            bg_height:imageHeight,
            bg_scale:factor,
            bg_offset_x:offsetX,
            bg_offset_y:offsetY,
            bg_source_type:filetype
          })

          
          var layers = []
          for(var layerInd = 0; layerInd< importLayers.length; layerInd++){
            var layer = importLayers[layerInd];
            if(layer && (layer.outline_selected  || layer.number_selected || layer.banquet_selected || layer.venue_selected)){
              layers.push(layer);
            }
          }
          if(layers.length > 0){
            props.setLoading(true)
          }
          var layers = []
          for(var layerInd = 0; layerInd< importLayers.length; layerInd++){
            var layer = importLayers[layerInd];
            if(layer && (layer.outline_selected  || layer.number_selected || layer.banquet_selected || layer.venue_selected)){
              layers.push(layer);
            }
          }
          layers.push({id:'importing',name:'importing'});
          props.setMainLayers(layers)

        }
      }
      else if(filetype == "dwg"){
        var {convertRateX, convertRateY, convertOffsetX, convertOffsetY, convertUnit} = getSvgInfo(mainSvg);
        var tmp_main = SVG(mainSvg); // real svg to used as main canvas background
        for(var layerInd = 0; layerInd< bgLayers.length; layerInd++){
          var layer = bgLayers[layerInd];
          var topgroup = tmp_main.find("#"+layer['id']);
          if(layer && !layer.bg_selected){
              if(topgroup && topgroup.length > 0){
                topgroup.remove();
              }
          }
          
          if(layer && layer.bg_selected){
            var subGroups = topgroup.find('g');
            if(subGroups && subGroups.length > 0){
              for(var i = 0; i< subGroups[0].length; i++){
                if(subGroups[0][i].node.style.fill!= ''){
                  subGroups[0][i].node.style.fill="transparent"
                }

                const computedStyle = window.getComputedStyle(subGroups[0][i].node);
                const strokeWidth = computedStyle.getPropertyValue('stroke-width');

                console.log("strokeWidth", strokeWidth)
                subGroups[0][i].node.style.stroke="black"
                let idealWidth = 2; //2feet
                if(mapUnit=='meter'){
                  idealWidth = 2/20 //10cm
                  if(convertUnit == 'mm'){
                    idealWidth = 20 //20cm
                  }
                }
                subGroups[0][i].node.style.strokeWidth=(strokeWidth?strokeWidth:1)*Math.min(idealWidth/(convertRateX?convertRateX:1),10)
                //2/(mapUnit=='meter'?20:1)/(convertRateX?convertRateX:1) means 2 inch or  2m/20 = 0.1m
              }
            }
          }
          if(layer && layer.outline_selected){
            var subGroups = topgroup.find('g');
            if(subGroups && subGroups.length > 0){
              for(var i = 0; i< subGroups[0].length; i++){
                // subGroups[0][i].node.style.fill="transparent"
                subGroups[0][i].node.style.stroke="black"
              }
            }
          }
          var textelements = topgroup.find('text');
          if(textelements && textelements.length > 0){
            for(var i = 0; i< textelements[0].length; i++){
              if(textelements[0][i].node.innerHTML == "For Demonstration Purposes Only"){
                textelements[0][i].remove();
              }
              if(textelements[0][i].node.innerHTML == "Tailor Made Software, Ltd"){
                textelements[0][i].remove();
              }
              if(textelements[0][i].node.innerHTML == "www.cadviewer.com"){
                textelements[0][i].remove();
              }
              if(textelements[0][i].node.innerHTML == "www.tailormade.com"){
                textelements[0][i].remove();
              }
            }
          }
        }
        var svgstring = tmp_main.svg();
        svgstring = svgstring.replace(/&quot;/g,"'");
        svgstring = svgstring.replace(/svgjs:/g,"");
          
  
        var data = svgstring
        var filePath = `map_bg/${uuidv4()}`
        var url = API_URL()+`uploadbgimg?ax-allow-ext=all&listtype=serverfolder&filePath=${filePath}&&start=0`
  
        var xhr = new XMLHttpRequest();
        xhr.open("POST", url);
  
        xhr.setRequestHeader("Accept", "image/apng");
        // xhr.setRequestHeader("Content-Type", "text/plain");
  
        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4) {
              var objecturl = xhr.responseText              
              props.setBgInfo({
                bg_url:objecturl,
                bg_width:imageWidth,
                bg_height:imageHeight,
                bg_scale:factor,
                bg_offset_x:offsetX,
                bg_offset_y:offsetY,
                bg_source_type:filetype
              })
              
              var layers = []
              for(var layerInd = 0; layerInd< importLayers.length; layerInd++){
                var layer = importLayers[layerInd];
                if(layer && (layer.outline_selected  || layer.number_selected || layer.banquet_selected || layer.venue_selected)){
                  layers.push(layer);
                }
              }
              if(layers.length > 0){
                props.setLoading(true)
              }
              var layers = []
              for(var layerInd = 0; layerInd< importLayers.length; layerInd++){
                var layer = importLayers[layerInd];
                if(layer && (layer.outline_selected  || layer.number_selected || layer.banquet_selected || layer.venue_selected)){
                  layers.push(layer);
                }
              }
              layers.push({id:'importing',name:'importing'});
              props.setMainLayers(layers)

          }
        };
  
        xhr.send((data));
      }
      
  
    }, 100)    
    onOk();
  }

  
  const handleCloseFilenameModal = ()=>{
    props.setOpenFilenameModal(false)
}

  const parseLayer = (svgStr)=>{
    if(svgStr){
        var drawermain = SVG(svgStr);
        var layerGroups = drawermain.find("[id*='layer_']");
        var layers = [];
        if(layerGroups && layerGroups.length>0){
            for(var loop = 0; loop < layerGroups.length; loop++){
                var layerGroup = layerGroups[loop];
                if(layerGroup){
                    var layerid = layerGroup.attr('id');
                    var layername = layerGroup.attr('cvjs:layername')
                    console.log("layername", layername)
                    console.log("layerid", layerid)
                    layers.push({id:layerid, name:layername, active:false})
                }
            }
        }
        return layers;
    }
}

  const getConvertedSvg=(convertedSvgInfo) => {
    if(convertedSvgInfo){
        axios.get(convertedSvgInfo.contentStreamData, { headers: {  } }).then(res=>{
            const result = res.data;
            const parser = new DOMParser();
            const svgDoc = parser.parseFromString(result, "application/xml");
            // var svgDoc = doc.contentDocument;
            var svgStr = svgDoc.getElementsByTagName('svg')[0].outerHTML;
            if(svgStr){
                var layers = parseLayer(svgStr)                        
                setBgLayers(layers)                
            }
            setDwgSvg(svgStr); 
        }).catch(error => {
            console.log("error", error);
        })
            
    }
  }

  const handleListItemClick = (value) => {
    // onClose(value);
    props.setLoading(true)
    setTimeout(function(){
      var tmp = [...bgLayers];
      for(var i = 0; i <tmp.length; i++){
          if(tmp[i]['id'] == value){
              tmp[i] = {...tmp[i], selected: true};
          }
          else{
            tmp[i] = {...tmp[i], selected: false};
          }
      }
      setMoldalImportLayers(tmp)
    }, 100);
  };

  const handleOutlineListItemClick = (value) => {
    // onClose(value);
    props.setLoading(true)
    setTimeout(function(){
      var tmp = [...bgLayers];
      for(var i = 0; i <tmp.length; i++){
          if(tmp[i]['id'] == value){
              tmp[i] = {...tmp[i], outline_selected: !tmp[i]['outline_selected'], selected: true};
          }
          else{
            tmp[i] = {...tmp[i], selected: false};
          }
      }
      setMoldalImportLayers(tmp)
    }, 100);
  };

  const handleNumberListItemClick = (value) => {
    // onClose(value);
    props.setLoading(true)
    setTimeout(function(){
      var tmp = [...bgLayers];
      for(var i = 0; i <tmp.length; i++){
          if(tmp[i]['id'] == value){
              tmp[i] = {...tmp[i], number_selected: !tmp[i]['number_selected'], selected: true};
          }
          else{
            tmp[i] = {...tmp[i], selected: false};
          }
      }
      setMoldalImportLayers(tmp)
    }, 100);
  };

  
  const handleBanquetListItemClick = (value) => {
    // onClose(value);
    props.setLoading(true)
    setTimeout(function(){
      var tmp = [...bgLayers];
      for(var i = 0; i <tmp.length; i++){
          if(tmp[i]['id'] == value){
              tmp[i] = {...tmp[i], banquet_selected: !tmp[i]['banquet_selected'], selected: true};
          }
          else{
            tmp[i] = {...tmp[i], selected: false};
          }
      }
      setMoldalImportLayers(tmp)
    }, 100);
  };

  
  const handleVenueListItemClick = (value) => {
    // onClose(value);
    props.setLoading(true)
    setTimeout(function(){
      var tmp = [...bgLayers];
      for(var i = 0; i <tmp.length; i++){
          if(tmp[i]['id'] == value){
              tmp[i] = {...tmp[i], venue_selected: !tmp[i]['venue_selected'], selected: true};
          }
          else{
            tmp[i] = {...tmp[i], selected: false};
          }
      }
      setMoldalImportLayers(tmp)
    }, 100);
  };

  
  const handleBGListItemClick = (value) => {
    // onClose(value);
    var dwgFlag = false
    props.setLoading(true)
    setTimeout(function(){
      var tmp = [...bgLayers];
      for(var i = 0; i <tmp.length; i++){
          if(tmp[i]['id'] == value){
            dwgFlag = (!tmp[i]['bg_selected'])
            tmp[i] = {...tmp[i], bg_selected: dwgFlag, selected: true};
          }
          else{
            tmp[i] = {...tmp[i], selected: false};
          }
      }
      setMoldalImportLayers(tmp)
      if(dwgFlag)
        setFiletype('dwg')
    }, 100);
  };

  const onChangeFile = (e, filetype)=>{
    setFiletype(filetype)
    var file = e.target.files[0]
    var filename = file.name
    if(file){
      if(filetype == "dwg"){
        var data = file.slice(0, file.size);
        var url = API_URL()+`uploadfile?ax-file-path=/content/drawings/dwg/&ax-allow-ext=all&listtype=serverfolder&ax-file-name=${filename}&&start=0`

        var xhr = new XMLHttpRequest();
        xhr.open("POST", url);

        xhr.setRequestHeader("Accept", "image/apng");
        // xhr.setRequestHeader("Content-Type", "text/plain");
        
        xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          var formdata = 
          {
              "action":"svg_creation",
              "converter":"AutoXchange AX2020",
              "version":"V1.00",
              "contentType":"file",
              "contentLocation":`${API_URL()}/content/drawings/dwg/${filename}`,
              "contentFormat":"DWG",
              "contentUsername":"",
              "contentPassword":"",
              "userLabel":"fromCADViewerJS",
              "contentResponse":"stream",
              "leaveStreamOnServer":0,
              "parameters":[
                {"paramName":"f",
                "paramValue":"svg"},
                // {"paramName":"RL",
                // "paramValue":"RM_"},
                // {"paramName":"TL","paramValue":"RM_TXT"},
                // {"paramName":"LA","paramValue":""},
                {"paramName":"model","paramValue":""},
                {"paramName":"extents","paramValue":""},
                {"paramName":"fpath","paramValue":"/converters/ax2022/linux/fonts/"},
                {"paramName":"WIDTH","paramValue":"10000"},
                {"paramName":"HEIGHT","paramValue":"10000"},
                {"paramName":"ShowAll","paramValue":""},        
              ]
          }
          var data = qs.stringify({
              request: JSON.stringify(formdata)
            });
          // var bodyFormData = new FormData();
          // bodyFormData.append('request', JSON.stringify(formdata));
          const options = {
              headers: {"content-type": 'application/x-www-form-urlencoded'}
          }
          axios.post('/callapiconversion', data, options).then(res=>{
              const result = res.data;
              getConvertedSvg(result);
              setImageWidth(3000)
              setImageHeight(3000)
          }).catch(error => {
              props.setLoading(false);
              console.log("error", error);
          })
        }};

        xhr.send((data));
      }
      if(filetype== "pdf"){
        var filename = file.name
        const reader = new FileReader();
        var data = file.slice(0, file.size);
        var filePath = `map_bg/${uuidv4()}`
        var url = API_URL()+`convertpdf2pic?ax-allow-ext=all&listtype=serverfolder&ax-file-name=${filename}&&filePath=${filePath}`

        var xhr = new XMLHttpRequest();
        xhr.open("POST", url);

        xhr.setRequestHeader("Accept", "image/apng");
        // xhr.setRequestHeader("Content-Type", "text/plain");

        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4) {
              console.log(xhr.responseText);
              var objecturl = xhr.responseText
              setImageUrl(objecturl)
              var img = new Image();
              img.onload = function(){
                setImageWidth(this.width)
                setImageHeight(this.height)
                var objects = step2Canvas.getObjects();
                for(var i = 0; i < objects.length; i++){
                  if(objects[i].get('class') == 'bg'){
                    step2Canvas.remove(objects[i])
                  }
                }              
                step2Canvas.setBackgroundImage(objecturl, step2Canvas.renderAll.bind(step2Canvas), {
                  opacity: 0.5,
                  angle: 0,                  
                  left: (offsetX?offsetX:0)*rate(), 
                  top: (offsetY?offsetY:0)*rate(), 
                  originX: 'left',
                  originY: 'top',
                  scaleX: rate()*factor,
                  scaleY: rate()*factor,
                  crossOrigin: 'anonymous',
                });
              }
              img.src = objecturl
          }
          props.setLoading(false);
        };

        xhr.send((data));
        props.setLoading(true);
      }
      else if(filetype == 'image'){
        const reader = new FileReader();
        var data = file.slice(0, file.size);
        var filePath = `map_bg/${uuidv4()}`
        var url = API_URL()+`uploadbgimg?ax-allow-ext=all&listtype=serverfolder&filePath=${filePath}&&start=0`

        var xhr = new XMLHttpRequest();
        xhr.open("POST", url);

        xhr.setRequestHeader("Accept", "image/apng");
        // xhr.setRequestHeader("Content-Type", "text/plain");

        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4) {
              console.log(xhr.responseText);
              
              // var objecturl = URL.createObjectURL(file);
              var objecturl = xhr.responseText
              setImageUrl(objecturl)
              var img = new Image();
              img.onload = function(){
                setImageWidth(this.width)
                setImageHeight(this.height)
                var objects = step2Canvas.getObjects();
                for(var i = 0; i < objects.length; i++){
                  if(objects[i].get('class') == 'bg'){
                    step2Canvas.remove(objects[i])
                  }
                }

                step2Canvas.setBackgroundImage(objecturl, step2Canvas.renderAll.bind(step2Canvas), {
                  opacity: 0.5,
                  angle: 0,                  
                  left: (offsetX?offsetX:0)*rate(), 
                  top: (offsetY?offsetY:0)*rate(), 
                  originX: 'left',
                  originY: 'top',
                  scaleX: rate()*factor,
                  scaleY: rate()*factor,
                  crossOrigin: 'anonymous',
                });
              }
              img.src = objecturl
          }
          props.setLoading(false);
        };

        xhr.send((data));
        props.setLoading(true);
      }
      e.target.value = null;
      e.target.value = null;
    }
  }

  const nextStep = ()=>{
    // if(step == 1){
    //   if(filetype == 'dwg'){
    //     setStep(step+1)
    //   }
    //   else
    //     setStep(3)
    // }
    // else{
      setStep(step+1)
    // }
  }

  const beforeStep = ()=>{
    // if(step == 3){
    //   if(filetype == 'dwg'){
    //     setStep(step-1)
    //   }
    //   else
    //     setStep(1)
    // }
    // else{
      setStep(step-1)
    // }
  }
  const dollyIn = ()=>{
    var zoom = importCanvas.getZoom()
    zoom = zoom * 1.3;	
    importCanvas.zoomToPoint({ x: importCanvas.width/2, y: importCanvas.height/2}, zoom);
    importCanvas.requestRenderAll();
  }
  const dollyOut = ()=>{
    var zoom = importCanvas.getZoom()
    zoom = zoom * 0.8;	
    importCanvas.zoomToPoint({ x: importCanvas.width/2, y: importCanvas.height/2}, zoom);
    importCanvas.requestRenderAll();
  }
  const swichFullView = ()=>{    
    importCanvas.viewportTransform[0] = 1;
    importCanvas.viewportTransform[3] = 1;
    importCanvas.viewportTransform[4] = 0;
    importCanvas.viewportTransform[5] = 0;
    importCanvas.requestRenderAll();
  }

  const onChangeUnit = (value)=>{
    if(value == 'feet'){
      // mainCanvas.set({
      //   mapUnit:'feet'
      // })
    }
    else{
      // mainCanvas.set({
      //   mapUnit:'meter'
      // })
    }
    setMapUnit(value)
  }

  console.log("expand_outline", expand_outline)
  return (
    <Dialog
      PaperComponent={PaperComponent}
      PaperProps={{drag_id:'import-dialog-title'}}
      aria-labelledby="import-dialog-title"
      fullWidth={true}
      maxWidth={"md"}
      id="background-modal"
      open={open}>
      <DialogTitle id='import-dialog-title' style={{display:'flex', alignItems:'center'}}><BackupIcon style={{marginRight: 10}}/>Import</DialogTitle>
      <DialogContent>
        <Stack sx={{ width: '100%', pt:'20px'}} spacing={4}>
          <Stepper alternativeLabel activeStep={step-1} connector={<ColorlibConnector />}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
        <Box>
          {
            (step == 1) &&
            <>
              <Grid
                container
                spacing={1}
                rowSpacing = {4}
              >
                <Grid item md={3}>
                  <Box mt={2} style={{borderRight:'1px solid rgb(237 237 237)', height:'610px', overflowY:'auto'}}>
                    <Box>
                      <Box pl={2}>
                        <FormControl>
                          <FormLabel>Unit</FormLabel>
                          <RadioGroup row
                            aria-labelledby=""
                            value={mapUnit}
                            onChange={(e)=>onChangeUnit(e.target.value)}
                          >
                            <FormControlLabel value="feet" control={<Radio size="small"/>} label="Feet (ft)" />
                            <FormControlLabel value="meter" control={<Radio size="small"/>} label="Meter (m)" />
                          </RadioGroup>
                        </FormControl> 
                      </Box>          
                    </Box>          
                    <Box mt={3}>
                      <Box pl={2} mb={2}>
                        <FormLabel>Select Layer</FormLabel>
                      </Box>          
                      <Accordion className = "boot-cat-select" expanded = {expand_val == 1} onChange={()=>setExpandVal(expand_val == 1?null:1)}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          
                        >
                          <Typography>Booth Outline</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 0 }}>
                          <List sx={{ pt: 0 }}>
                            {importLayers.map((layer, index) => (
                              <ListItem key={index} style={{fontSize:12}} button onClick={() => handleOutlineListItemClick(layer['id'])}>
                                <ListItemAvatar className="layeravada">
                                    {layer['outline_selected']?<span style={{color:'green', fontWeight:'bold'}}>On</span>:<span>Off</span>}
                                </ListItemAvatar>
                                <ListItemText  className = "layertext" primary={layer['name']} />
                              </ListItem>
                            ))}
                          </List>
                        </AccordionDetails>
                      </Accordion>          
                      <Accordion className = "boot-cat-select" expanded = {expand_val==2} onChange={()=>setExpandVal(expand_val == 2?null:2)}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>Booth Number</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 0 }}>
                          <List sx={{ pt: 0 }}>
                            {importLayers.map((layer,index) => (
                              <ListItem key={index} style={{fontSize:12}} button onClick={() => handleNumberListItemClick(layer['id'])}>
                                <ListItemAvatar className="layeravada">
                                    {layer['number_selected']?<span style={{color:'green', fontWeight:'bold'}}>On</span>:<span>Off</span>}
                                </ListItemAvatar>
                                <ListItemText className = "layertext" primary={layer['name']} />
                              </ListItem>
                            ))}
                          </List>
                        </AccordionDetails>
                      </Accordion>     
                      <Accordion className = "boot-cat-select" expanded = {expand_val == 3} onChange={()=>setExpandVal(expand_val == 3?null:3)}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>Banquet Furniture</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 0 }}>
                          <List sx={{ pt: 0 }}>
                            {importLayers.map((layer,index) => (
                              <ListItem key={index} style={{fontSize:12}} button onClick={() => handleBanquetListItemClick(layer['id'])}>
                                <ListItemAvatar className="layeravada">
                                    {layer['banquet_selected']?<span style={{color:'green', fontWeight:'bold'}}>On</span>:<span>Off</span>}
                                </ListItemAvatar>
                                <ListItemText className = "layertext" primary={layer['name']} />
                              </ListItem>
                            ))}
                          </List>
                        </AccordionDetails>
                      </Accordion>             
                      <Accordion className = "boot-cat-select" expanded = {expand_val == 5} onChange={()=>setExpandVal(expand_val == 5?null:5)}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>Venue Columns</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 0 }}>
                          <List sx={{ pt: 0 }}>
                            {importLayers.map((layer,index) => (
                              <ListItem key={index} style={{fontSize:12}} button onClick={() => handleVenueListItemClick(layer['id'])}>
                                <ListItemAvatar className="layeravada">
                                    {layer['venue_selected']?<span style={{color:'green', fontWeight:'bold'}}>On</span>:<span>Off</span>}
                                </ListItemAvatar>
                                <ListItemText className = "layertext" primary={layer['name']} />
                              </ListItem>
                            ))}
                          </List>
                        </AccordionDetails>
                      </Accordion>           
                      <Accordion className = "boot-cat-select" expanded = {expand_val == 4} onChange={()=>setExpandVal(expand_val == 4?null:4)}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>Background</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 0 }}>
                          <List sx={{ pt: 0 }}>
                            {importLayers.map((layer,index) => (
                              <ListItem key={index} style={{fontSize:12}} button onClick={() => handleBGListItemClick(layer['id'])}>
                                <ListItemAvatar className="layeravada">
                                    {layer['bg_selected']?<span style={{color:'green', fontWeight:'bold'}}>On</span>:<span>Off</span>}
                                </ListItemAvatar>
                                <ListItemText onClick={() => handleListItemClick(layer['id'])} className = "layertext" primary={layer['name']} />
                              </ListItem>
                            ))}
                          </List>
                        </AccordionDetails>
                      </Accordion>  
                    </Box>                  
                  </Box>
                </Grid>
                <Grid item md={9}>
                  <Box mt={2}>
                    <label className='import-bg-note'>
                      {expand_val == 4?
                        <>When selecting background layers, please only include layers you intend on appear on the map.</>
                        :<>&nbsp;</>
                      }
                    </label>
                  </Box>
                  <Box>
                    <canvas id="step-1-canvas" width="625px" height="625px"/>
                    <div className="controls -ready import">
                        <Button title="Zoom In" onClick={()=>{dollyIn()}}><ZoomInIcon/></Button>
                        <Button style={{ marginTop: '5px' }}  title="Zoom Out" onClick={()=>{dollyOut()}}><ZoomOutIcon/></Button>
                        <Button style={{ marginTop: '5px' }}  title="Auto Fit on Screen" onClick={()=>{swichFullView()}}><FullscreenIcon/></Button>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </>
          }
          {
            (step == 2 || step == 3) &&
            <Box mt={4}> 
              {step == 2 &&             
              <Box>
                <Grid
                  container
                  spacing={2}
                  rowSpacing = {4}
                >
                  <Grid item md={6}>
                    <Box display={'flex'}>
                      <FormControl style={{marginRight:20}}>
                        <Button variant="contained" onClick={()=>{openRef1.current.click()}} color="primary" endIcon = {<FileUpload/>}>
                          Upload Image
                        </Button>
                        <input type="file" ref={openRef1} style={{display:"none"}} onChange={(e)=>onChangeFile(e, 'image')} />
                      </FormControl>
                      <FormControl>
                        <Button variant="contained" onClick={()=>{openRef2.current.click()}} color="primary" endIcon = {<FileUpload/>}>
                          Upload Pdf
                        </Button>                      
                        <input type="file" ref={openRef2} style={{display:"none"}} onChange={(e)=>onChangeFile(e, 'pdf')}  accept='.pdf'/>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              }
              <Box mt={4}>
                <Grid
                  container
                  spacing={0}
                  rowSpacing = {4}
                >
                  <Grid item md={step == 2?9:12}>
                    <Box class="render-booth-container" id="render-booth-container" style={{position:'relative', width:renderWidth, height:renderHeight, overflow: 'hidden'}}>
                     
                      <Box style={{position:'absolute', width:'100%', height: '100%', left: ((offsetX?offsetX:0)*rate()), top: ((offsetY?offsetY:0)*rate())}}>
                        {/* {
                          <canvas id="step-2-bg-canvas" width="625px" height="500px"/>
                        }
                        {
                          filetype== "pdf" &&
                            <img width={rate()*imageWidth*factor} height="auto" src={imgUrl}/>
                        }
                        {
                          filetype== "image" &&
                            <img width={rate()*imageWidth*factor} height="auto" src={imgUrl}/>
                        }  */}
                      </Box>
                      <Box>
                          <canvas id="step-2-canvas" width="625px" height="500px"/>
                      </Box>
                    </Box>
                  </Grid>
                  {step == 2 &&
                  <Grid item md={3}>
                    <Box pl={2} style={{borderLeft:'1px solid rgb(237 237 237)', height:'100%'}}>
                      <Box mb={3}>
                        <TextField
                          id="outlined-required"
                          label="Width"
                          defaultValue="0"
                          value={imageWidth}
                          onChange={(e)=>setImageWidth(e.target.value)}
                          disabled={true}
                        />
                      </Box>
                      <Box mb={3}>
                        <TextField
                          id="outlined-required"
                          label="Height"
                          defaultValue="0"
                          value={imageHeight}
                          onChange={(e)=>setImageHeight(e.target.value)}
                          disabled={true}
                        />
                      </Box>
                      <Box mb={3}>
                        <TextField
                          id="outlined-required"
                          label="Scale"
                          defaultValue="0"
                          value={factor}
                          type = 'number'
                          onChange={(e)=>setFactor(e.target.value)}
                          // onBlur={(e)=>setFactor(e.target.value)}
                        />
                      </Box>
                      <Box mb={3}>
                        <TextField
                          id="outlined-required"
                          label="Offset X"
                          defaultValue="0"
                          value={offsetX}
                          type = 'number'
                          onChange={(e)=>setOffsetX(e.target.value)}
                        />
                      </Box>
                      <Box>
                        <TextField
                          id="outlined-required"
                          label="Offset Y"
                          defaultValue="0"
                          value={offsetY}
                          type = 'number'
                          onChange={(e)=>setOffsetY(e.target.value)}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  }
                </Grid>
              </Box>
            </Box>
          }
          {/* {
            (step == 3) &&
            <Box mt={4}>
              <Grid
                container
                spacing={2}
                rowSpacing = {4}
              >
                <Grid item md={12}>
                  <Box class="render-booth-container" id="render-booth-container" style={{position:'relative', width:renderWidth, height:renderHeight, overflow: 'hidden'}}>
                    <Box>
                          <canvas id="step-2-canvas" width="625px" height="500px"/>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          } */}
        </Box>
      </DialogContent>
      <DialogActions>
        {step > 1 &&
          <>
            <Button autoFocus onClick={()=>beforeStep(step)}>
              <b>Back</b>
            </Button>
          </>
        }  
        {step < 3 &&
          <Button autoFocus onClick={()=>{nextStep(step); }}>
            <b>Next</b>
          </Button>
        }    
        {step == 3 &&
          <>
            <Button autoFocus onClick={()=>props.setOpenFilenameModal(true)}>
              <b>Set</b>
            </Button>
          </>
        }
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
      <FilenameModal
            open={props.ui.openFilenameModal}
            onClose={handleCloseFilenameModal}
            onChange = {(e)=>props.setSaveFileName(e.target.value)}
            onOk={()=>{onSet()}}
      />
    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
      ui: state.ui,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
      setLoading: value=>dispatch(setLoading(value)),
      setMainLayers: layers=>dispatch(setMainLayers(layers)),
      setImportLayers: layers=>dispatch(setImportLayers(layers)),
      setBgInfo: info=>dispatch(setBgInfo(info)),
      setOpenFilenameModal:  data=>dispatch(setOpenFilenameModal(data)),
      setSaveFileName:  data=>dispatch(setSaveFileName(data)),
      resetUI:  data=>dispatch(resetUI(data)),
      resetMain:  data=>dispatch(resetMain(data)),
      setBoothFileList: data=>dispatch(setBoothFileList(data)),
      setMainSvg: svg=>dispatch(setMainSvg(svg)),
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(Index);