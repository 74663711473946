export const SET_SEARCH_KEY_SELECT = 'SET_SEARCH_KEY_SELECT'
export const SET_SEARCH_OPTIONS = 'SET_SEARCH_OPTIONS'
export const SET_SEARCH_PULLDOWN_VALUE = 'SET_SEARCH_PULLDOWN_VALUE'
export const SET_SEARCH_INPUT_VALUE = 'SET_SEARCH_INPUT_VALUE'

export const setSearchKeySelect = data =>({
    type:SET_SEARCH_KEY_SELECT,
    payload:data
});

export const setSearchOptions = data =>({
    type:SET_SEARCH_OPTIONS,
    payload:data
});

export const setSearchPulldownValue = data =>({
    type:SET_SEARCH_PULLDOWN_VALUE,
    payload:data
});

export const setSearchInputValue = data =>({
    type:SET_SEARCH_INPUT_VALUE,
    payload:data
});